
import { Collapse } from '@mui/material';
import { Button, Flex, Text } from '@radix-ui/themes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdRemove } from 'react-icons/io';
import { IoIosAdd } from 'react-icons/io';

import { Product_interface } from '@/interfaces/product.interfaces';

interface ControlProduct_interface {
  handleAdd: () => void, 
  handleRemove: () => void,
  count: number
}

interface ProductComponent_interface {
  item: Product_interface,
  addProduct: (p: Product_interface) => void,
  removeProduct: (p: Product_interface) => void,
  defaultCount: number | undefined
}

const Product = ({item, addProduct, removeProduct, defaultCount = 0}: ProductComponent_interface) => {
  const [count, setCount] = useState<number>(defaultCount);
  const [open, setOpen] = useState(false);

  const toggleDescription = () => setOpen(!open);

  const handleAddProduct = () => {
    if(item.max_items && count >= item.max_items) return;
    
    setCount(prev => prev + 1);
    addProduct(item);
  };

  const handleRemoveProduct = () => {
    if(count > 0){
      setCount(prev => prev - 1);
      removeProduct(item);
    }
  };

  const isLargeDescription = item.description && item.description.length > 72;
  const description = item.description && isLargeDescription ? item.description.slice(0, 72) : item.description;

  return (
    <Flex
      width="100%"
      direction="column"
      gap="9px"
      style={{
        padding: '15px 9px',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px',
        borderRadius: '3px',
      }}
    >
      <Flex justify="between" align="center">
        <Text size="3" weight="regular">{item.name}</Text>
        <Text size="6" weight="medium">
          {/* <Text size="2" weight="light">$ </Text> */}
          {item.price}
          <Text size="1" weight="light"> {item.currency}</Text>
        </Text>
      </Flex>

      <Flex align="center" gap="2" justify="between">

        <Collapse in={open} collapsedSize={34}>
          {
            isLargeDescription ? (
              <Flex align="center" justify={'between'} gap="2" onClick={toggleDescription}>
                <Text size="1" weight="light" align="left" >
                  {open ? item.description : `${description}...`} 
                  {!open && <Text size="1" weight="light" color='indigo' style={{ cursor: 'pointer' }}> Ver más</Text>}
                </Text>
              </Flex>
            ) : (
              <Text size="1" weight="light" align="left">{description}</Text>
            )
          }
        </Collapse>

        <ControlProduct handleAdd={handleAddProduct} handleRemove={handleRemoveProduct} count={count} />
      </Flex>
    </Flex>
  );
};


const ControlProduct = ({ handleAdd, handleRemove, count }: ControlProduct_interface) => {
  const { t } = useTranslation();

  if(count > 0){
    return(
      <Flex gap={'4'} align={'center'} style={{border: '1px solid indigo', padding: '3px 9px', borderRadius: '6px'}}>
        <Button size={'4'} variant='ghost' onClick={handleRemove}>
          <IoMdRemove/>
        </Button>

        <Text size={'3'} weight={'medium'}>{count}</Text>

        <Button size={'4'} variant='ghost' onClick={handleAdd}>
          <IoIosAdd/>
        </Button>

      </Flex>
    );
  }
  
  return(
    <Button size={'2'} variant='outline' onClick={handleAdd}>{t('cart.add')}</Button>
  );
};

export default Product;