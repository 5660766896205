import { useMutation } from '@tanstack/react-query';

import api from '@/api';
import { STRIPE } from '@/assets/Constants/CHAINS';
import { CoinPaymentRequest_interface } from '@/interfaces/token.interface';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';


const useAddTransactionPayment = () => {
  const { getToken, getChain, isPaymentCardUSD } = usePaymentMethodProvider();

  const mutationFn = ({data, id}: {data: CoinPaymentRequest_interface, id: string}) => {
    if(isPaymentCardUSD){
      return api.put(`/payment_requests/${id}/card_payment`);
    }
    return api.put(`/payment_requests/${id}/add_transaction`, data);
  };
  
  const mutation = useMutation({mutationFn});

  return {
    ...mutation,
  };
};

export default useAddTransactionPayment;