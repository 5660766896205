import QRCode from 'react-qr-code';

const QRCodeComponent = ({value}: {value: string}) => {
  return(
    <div style={{width: '180px', height: '180px'}}>
      <QRCode level='L' value={value} style={{ height: 'auto', maxWidth: '100%', width: '100%'}}/>
    </div>
  );
};

export default QRCodeComponent;