import './style.css';

import { Box, Flex, Select as SelectRadix, Spinner, Text } from '@radix-ui/themes';
import { ReactNode } from 'react';

import OptionBadge from './OptionBadge';
import { SelectProps } from './select.interface';
import TitleSelect from './Title.select';

interface TitleSelect_interface extends SelectProps {
  title: string;
  action?: ReactNode,
  disclaimers?: Record<any, string>
}

const Select = ({ options, defaultValue, label, value, setValue, loading, disabled, placeholder, optionsBadge, titleBadge }: SelectProps) => {

  const onValueChange = (e: any) => {
    // e.stopPropagation(); //TODO
    setValue(e);
  };

  if(loading){
    return(
      <SelectRadix.Root disabled={true} value={value} onValueChange={onValueChange} defaultValue={defaultValue} size={'3'}>
        <SelectRadix.Trigger style={{width: '100%'}} placeholder={placeholder}>
          <Spinner />
        </SelectRadix.Trigger>
      </SelectRadix.Root>
    );
  }

  return (
    <SelectRadix.Root disabled={disabled} value={value} onValueChange={onValueChange} defaultValue={defaultValue} size={'3'}>
      <SelectRadix.Trigger style={{width: '100%'}} placeholder={placeholder}/>
      <SelectRadix.Content position='popper' style={{ width: '100%'}}>
        <SelectRadix.Group style={{ width: '100%'}}>
          {
            label && <SelectRadix.Label>{label}</SelectRadix.Label>
          }

          {
            options?.map((op) => (
              <SelectRadix.Item
                key={op.value} 
                disabled={op.disabled}
                value={op.value?.toString()}
                style={{ width: '100%'}}
              >
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',gap: '21px'}}>
                  <div style={{display: 'flex', alignItems: 'center', gap: '9px'}}>
                    {op.url_icon && (
                      <img src={op.url_icon} width={'21px'} height={'21px'}/>
                    )}
                    <Text size={'3'} weight={'medium'}>{op.label}</Text>
                  </div>
                  {
                    <OptionBadge title={titleBadge} badges={optionsBadge} option={op} key={`${op.value}-badges`} />
                  }
                </div>
              </SelectRadix.Item>
            ))
          }
        </SelectRadix.Group>
      </SelectRadix.Content>
    </SelectRadix.Root>
  );
};

const Title = ({ title, action, value, ...rest }: TitleSelect_interface) => {
  return(
    <Flex justify={'start'} direction={'column'}>
      <Flex justify={'between'} width={'100%'} align={'center'}>
        <TitleSelect title={title} />
        {action}
      </Flex>
      <Select value={value} {...rest}/>
    </Flex>
  );
};

const Disclaimer = ({ title, action, disclaimers, value, optionsBadge, ...rest }: TitleSelect_interface) => {
  const optionBadge = optionsBadge?.find(o => o.name === value);

  return(
    <Flex justify={'start'} direction={'column'}>
      <Flex justify={'between'} width={'100%'} align={'center'}>
        <TitleSelect title={title} />
        {action}
      </Flex>
      <Select value={value} optionsBadge={optionsBadge} {...rest}/>
      {
        (optionBadge && optionBadge.discount > 0) && (
          <Text style={{fontSize: '10px',height: '15px'}} color='gray'>{disclaimers?.[value]}</Text>
        )
      }
    </Flex>
  );
};

Select.Title = Title;
Select.Disclaimer = Disclaimer;

export default Select;