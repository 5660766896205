import { PaymentRequest_interface } from '@/interfaces/transaction.interface';

export const isPaymentExpired = (payment: PaymentRequest_interface | null): boolean => {
  if(!payment) return false;

  if(payment.status === 'expired' && payment.status_detail === 'not_paid') return true;

  return false;
};

export const isPaymentSuccess = (payment: PaymentRequest_interface | null): boolean => {
  if(!payment) return false;

  if(payment.status === 'approved') return true;

  if(payment.status === 'expired' && payment.status_detail === 'correct') return true;

  return false;
};

export const isPaymentPending = (payment: PaymentRequest_interface | null): boolean => {
  if(!payment) return false;

  if(payment.status === 'pending') return true;

  return false;
};