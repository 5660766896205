import { Skeleton, Text } from '@radix-ui/themes';

interface ConvertionRate_interface {
  rate: number | null;
  loading: boolean
}

const ConvertionRate = ({rate, loading}: ConvertionRate_interface) => {


  return (
    <Skeleton loading={loading}>
      <Text size={'1'} weight={'regular'} align={'left'} color="gray"> {`≈ ${rate?.toFixed(2)} USDT`}</Text>
    </Skeleton>
  );
};

export default ConvertionRate;