export const copyToClipboard = (text: string) => {
  try {
    navigator.clipboard.writeText(text);
  } catch (err) {
    copyUsingFallback(text);
  }
};

const copyUsingFallback = (text: string) => {
  // Crear un textarea temporal
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};