import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteLoaderData } from 'react-router-dom';

import { SUPPORTED_CHAINS } from '@/assets/Constants/CHAINS';
import Select from '@/components/Select/Select';
import { OptionSelect } from '@/components/Select/select.interface';
import { getChainsInCurrencies, getCurrenciesForToken } from '@/helpers/filters.multiuse';
import { Chain } from '@/interfaces/chain.interfaces';
import { Merchant_interface } from '@/interfaces/merchant.interfaces';
import { CurrenciesApi_interface } from '@/interfaces/token.interface';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';

interface SelectChain_interface{
  currencies: CurrenciesApi_interface[],
  disabled?: boolean
}

const formatChainsOption = (chains: Chain[]): OptionSelect[]  => {
  return chains.map(chain => ({
    label: chain.name,
    value: chain.id,
    url_icon: chain.icon?.url,
  } as OptionSelect));
};

const SelectChain = ({currencies, disabled}: SelectChain_interface) => {
  const { getWallet, getChain, setChain, getToken } = usePaymentMethodProvider();
  const merchant: Merchant_interface = useRouteLoaderData('root') as Merchant_interface;
  const { t } = useTranslation();

  const wallet = getWallet();
  const token = getToken();

  const defaultChains = useMemo(() => formatChainsOption(SUPPORTED_CHAINS), []);

  const badgesChains = [
    {
      name: '-2',
      discount: merchant?.stripe_credentials?.extra_charge ?? 0
    }
  ];
  
  const optionsChains = useMemo(() => {
    if(!wallet && !token) return defaultChains; //Si no tiene Token ni Wallet, retorna todas las Chains

    if(wallet && !token) return formatChainsOption(wallet.supportsChains);

    if(!token) return []; //Nunca va a llegar aca, comprobacion para TS

    const currenciesForToken = getCurrenciesForToken(currencies, token); //retorna las currencies para el Token seleccionado

    //Si tiene Token, pero no Wallet
    if(!wallet && token) {
      const chainsInCurrencies = getChainsInCurrencies(currenciesForToken, SUPPORTED_CHAINS); // retorna las Chains para las currencies del Token
      return formatChainsOption(chainsInCurrencies);
    }

    if(!wallet) return []; //Nunca va a llegar aca, comprobacion para TS

    //Si tiene Token y Wallet
    const chainsInCurrenciesForToken = getChainsInCurrencies(currenciesForToken, wallet.supportsChains);
    return formatChainsOption(chainsInCurrenciesForToken);

  }, [wallet, token, defaultChains, currencies]);

  const chain = getChain()?.id.toString();


  useEffect(() => {
    if(optionsChains.length === 1){ //Si es la unica Chain, la pre-selecciono
      setChain(Number(optionsChains[0].value));
      return;
    }
    if(chain && !optionsChains.find(op => op.value.toString() === chain)){ //Si la Chain seleccionada anteriormente no esta en las actuales
      setChain(null);
      return;
    }
  }, [chain, wallet, token, setChain, optionsChains]);

  return (
    <Select.Title
      title={t('Red')}
      label={t('Seleccioná una red')}
      options={optionsChains}
      setValue={(c) => setChain(c)}
      value={chain ?? ''}
      placeholder={t('Seleccionar red')}
      disabled={disabled}
      optionsBadge={badgesChains}
      titleBadge={t('% extra charge')}
    />
  );
};

export default SelectChain;