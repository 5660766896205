import { useLocation, useSearchParams } from 'react-router-dom';

const useCheck = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();


  const isStatic = (): boolean => {
    return searchParams.get('static') === 'true' ||  location.pathname.includes('static');
  };

  return ({
    isStatic
  });
};

export default useCheck;