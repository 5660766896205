
export const setItemStorage = (key: string, value: string | number): void => {
  localStorage.setItem(key, value.toString());
};

export const getItemStorage = (key: string): string | null => {
  return localStorage.getItem(key);
};

export const removeItemStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const setItemSession = (key: string, value: string | number) => {
  sessionStorage.setItem(key, value.toString());
};

export const getItemSession = (key: string) => {
  return sessionStorage.getItem(key);
};