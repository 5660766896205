import { browserName } from 'react-device-detect';

export const isDevelop = (): boolean => {
  return process.env.REACT_APP_DEVELOPMENT === 'true';
};

export const isCoinbaseWallet = (): boolean => {
  return window.ethereum?.isCoinbaseWallet || window.ethereum?.isCoinbaseBrowser || false;
}; 

export const isMetamask = (): boolean => {
  return window.ethereum?.isMetaMask ?? false;
};

export const isMobile = (): boolean => {
  const userAgent = navigator.userAgent;
  // Detecta dispositivos móviles
  return (/android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)); 
};

export const isBrave = (): boolean => {
  return browserName === 'Brave' || browserName === 'brave';
};