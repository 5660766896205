//TODO switch chain wallet

import { useActiveWalletChain, useSwitchActiveWalletChain } from 'thirdweb/react';

import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';

interface UseSwitchChain_interface{
  switchChain: () => Promise<boolean>
}

const useSwitchChain = (): UseSwitchChain_interface => {
  const activeWalletChain = useActiveWalletChain();
  const switchChainForActiveWallet = useSwitchActiveWalletChain();
  const { getChain } = usePaymentMethodProvider();

  const switchChain = async (): Promise<boolean> => {
    const chain = getChain();
    
    if (!chain || !activeWalletChain) return false;

    if (activeWalletChain.id !== chain.id) {
      try {
        await switchChainForActiveWallet(chain);
        return true;
      } catch (e) {
        console.error('Error switching chain', e);
        return false;
      }
    }
    
    return true;
  };

  return({
    switchChain
  });
};

export default useSwitchChain;