import { createContext, useContext, useState } from 'react';

import { ChildrenType } from '@/types/generics.types';
import { UserStatus_type } from '@/types/user.types';


interface UserStatusContext_interface {
  getStatus: () => UserStatus_type;
  setStatus: (status: UserStatus_type) => void;
  isPending: boolean;
  isInProcess: boolean;
  isSuccess: boolean;
  isError: boolean;
  isInTransfer: boolean;
  isLoader: boolean;
}

export const UserStatusContext = createContext<UserStatusContext_interface | null>(null);


const UserStatusProvider = ({ children }: ChildrenType) => {
  const [status, setStatusState] = useState<UserStatus_type>('pending' as UserStatus_type);

  const isPending = status === 'pending';
  const isInProcess = status === 'in_process';
  const isSuccess = status === 'success';
  const isError = status === 'error';
  const isInTransfer = status === 'in_transfer';
  const isLoader = status === 'loader';

  const getStatus = (): UserStatus_type => {
    return status;
  };

  const setStatus = (status: UserStatus_type): void => {
    setStatusState(status);
  };



  return (
    <UserStatusContext.Provider value={{
      getStatus,
      setStatus,
      isPending,
      isInProcess,
      isSuccess,
      isError,
      isInTransfer,
      isLoader
    }}>
      {children}
    </UserStatusContext.Provider>
  );
};

export const useUserStatusProvider = (): UserStatusContext_interface => {
  const context = useContext(UserStatusContext);
  if (context === null) {
    throw new Error('useUserStatusProvider must be used within a UserStatusProvider');
  }
  return context;
};

export default UserStatusProvider;