import { Flex, Text } from '@radix-ui/themes';

import { ProductCart_interface } from '@/interfaces/product.interfaces';
import { useCartContext } from '@/providers/Cart.provider';

const TotalCart = () => {
  const { getCart } = useCartContext();
  const cart = getCart();

  if(cart.length > 0){
    const total = cart.reduce((acc: number, curr: ProductCart_interface) => {
      if(!curr.quantity) return curr.price;
      return acc + curr.price * curr.quantity;
    }, 0);

    return (
      <Flex direction={'column'} gap={'1'}>
        <Text size={'2'} weight={'regular'}>Total</Text>
        <Text size={'5'} weight={'medium'}>$ {total.toLocaleString('es-ES')}</Text>
      </Flex>
    );
  }
};

export default TotalCart;