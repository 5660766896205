import { useQueryClient } from '@tanstack/react-query';

import api from '@/api';
import { Product_interface } from '@/interfaces/product.interfaces';
import { PaymentRequest_interface } from '@/interfaces/transaction.interface';
import { useCartContext } from '@/providers/Cart.provider';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';

import useCustomNavigation from '../useCustomNavigation';

interface UseGetProduct_interface {
  fetchProduct: (id?: string) => Promise<Product_interface | undefined>
} 
export const useGetProduct = (): UseGetProduct_interface => {
  const { getProduct, setProduct } = useCartContext();
  const { setPaymentRequest } = usePaymentRequestProvider();
  const { navigateError } = useCustomNavigation();
  const queryClient = useQueryClient();

  const fetchProduct = async (id?: string): Promise<Product_interface | undefined> => {
    const productData = getProduct();
    if(productData) return productData;

    if(id){
      try {
        const response = await queryClient.fetchQuery({
          queryKey: ['product', id],
          queryFn: () => api.get(`/products/${id}`),
        });
        if(response.status === 200){
          const product = response.data as Product_interface;
          setProduct(product);

          const paymentRequest = {
            amount: product.price,
            currency: product.currency,
            reference: {
              _product_id: product.id,
            }
          } as PaymentRequest_interface;
          
          setPaymentRequest(paymentRequest);
        }
        return response.data;
      } catch (error) {
        navigateError();
      }
    }
  };

  return({
    fetchProduct
  });
};