import { Drawer } from '@mui/material';
import { Button, Flex, Text, Theme } from '@radix-ui/themes';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useCartContext } from '@/providers/Cart.provider';

import CartItem from './CartItem';

const CartDrawer = ({open, setOpen}: {open: boolean, setOpen: Dispatch<SetStateAction<boolean>>}) => {
  const { getCart } = useCartContext();
  const cart = getCart();
  const { t } = useTranslation();

  if(!cart || cart.length === 0) return;

  return (
    <Drawer
      anchor={'bottom'}
      open={open}
      onClose={() => setOpen(false)}
    >
      <Theme accentColor='iris'>
        <div style={{padding: '18px 15px'}}>
          <Text size={'4'} weight={'medium'} style={{marginBottom: '9px'}}>{t('cart.drawer.title')}</Text>
          <Flex 
            direction={'column'} 
            gap={'9px'} 
            style={{  
              padding: '21px 0',
              maxHeight: '60vh',
              overflow: 'scroll'
            }}
          >
            {
              cart.map((item) => (
                <CartItem key={`Cart-item-${item.id}`} item={item}/>
              ))
            }
          </Flex>
          <Button style={{width: '100%'}} onClick={() => setOpen(false)}>{t('actions.Cerrar')}</Button>
        </div>
      </Theme>
    </Drawer>
  );
};

export default CartDrawer;