import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import api from '@/api';
import { createPayment_interface } from '@/interfaces/transaction.interface';

//TODO typescript

export const useCreatePayment = () => {
  const mutation = useMutation({
    mutationFn: (paymentData: createPayment_interface) => api.post('/payment_requests', paymentData),
    onError: (e: any) => {
      const detail = e.response?.data?.detail;
      if(detail){
        toast.error(detail);
      }
    }
  });

  return {
    ...mutation,
  };
};
