import { useQuery, UseQueryResult } from '@tanstack/react-query';

import api from '@/api';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';
import { TokenSymbol_Type } from '@/types/token.types';

interface useApi_interface {
  useGetChains: () => UseQueryResult<any, Error>;
  useGetRateForCoin: (coin: TokenSymbol_Type | null) => UseQueryResult<any, Error>;
}

const useApi = (): useApi_interface => {
  const { getCurrency } = usePaymentRequestProvider();

  const getQuery = async (path: string) => {
    const res = await api.get(path);
    return res;
  };

  const useGetChains = () => {
    const query = useQuery({ queryKey: ['chains'], queryFn: () => getQuery('/chains')});
    return query;
  };

  const useGetRateForCoin = (coin: TokenSymbol_Type | null) => {
    const currency = getCurrency();
    const query = useQuery({
      queryKey: ['rates', coin, currency],
      queryFn: () => getQuery(`/rates?coin=${coin}&fiat=${currency}`),
      enabled: !!coin
    });
    return query;
  };

  return ({ 
    useGetChains,
    useGetRateForCoin,
    
  });
};

export default useApi;