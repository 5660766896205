import { useConnect, useConnectModal } from 'thirdweb/react';
import { createWallet, Wallet } from 'thirdweb/wallets';

import { isBrave, isCoinbaseWallet, isMetamask, isMobile } from '@/helpers/global.state';
import { Wallet_WalletConnect } from '@/interfaces/wallet.interface';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';

import useAppMetadata from '../useAppMetadata';
import useClient from '../useClient';

interface UseConnection_interface{
  connectWallet: () => Promise<Wallet | null>;
}

const CoinbaseWalletConfig = createWallet('com.coinbase.wallet');

const MetamasWalletConfig = createWallet('io.metamask');

const wallets  = [MetamasWalletConfig, CoinbaseWalletConfig, createWallet('com.beexo'), createWallet('io.zerion.wallet')];

const useConnection = (): UseConnection_interface => {
  const client = useClient();
  const { getWallet, getChain } = usePaymentMethodProvider();
  const { connect: connectModal } = useConnectModal();
  const { connect } = useConnect();
  const appMetadata = useAppMetadata();
  const [walletMethod, chain] = [getWallet() as Wallet_WalletConnect, getChain()];

  const connectModalWallet = async () => {
    if(!walletMethod || !chain) return null;
    return await connectModal({
      client,
      chain,
      appMetadata,
      wallets,
      size: 'compact',
      theme: 'light',
      showThirdwebBranding: false,
      showAllWallets: false,
      // walletConnect: {projectId: 'c4963c3fff9201f76e3729e480713cef'},
      title: 'Connect Wallet',
      titleIcon: 'https://simplefi.s3.us-east-2.amazonaws.com/icon.png',
    });
  };

  const connectCoinbaseWallet = async () => {
    if(!walletMethod || !chain) return null;

    await window.ethereum.request({ method: 'eth_requestAccounts' });

    return connect(async () => {
      const wallet = CoinbaseWalletConfig;
      await wallet.connect({
        client,
        chain: {
          id: chain.id,
          name: chain.name,
          rpc: chain.rpc
        },
      });
      return wallet;
    });
  };

  const connectMetamaskWallet = async () => {
    if(!walletMethod || !chain) return null;

    return connect(async () => {
      const wallet = MetamasWalletConfig;
      await wallet.connect({
        client,
        chain: {
          id: chain.id,
          name: chain.name,
          rpc: chain.rpc
        },
      });
      return wallet;
    });
  };

  const connectWallet = async (): Promise<Wallet | null> => {
    if(!walletMethod || !chain) return null;

    try{

      if(isMetamask() && isMobile() && !isBrave()){
        return await connectMetamaskWallet();
      }

      if(isCoinbaseWallet() && isMobile() && !isBrave()){
        return await connectCoinbaseWallet();
      }

      return await connectModalWallet();

    }catch (e: any) {
      console.error('Error connection wallet', e);
    }
    return null;
  };

  return ({
    connectWallet
  });
};

export default useConnection;