import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import api from '@/api';
import { PaymentRequest_interface } from '@/interfaces/transaction.interface';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';
import { PaymentRequestStatus } from '@/types/transaction.types';

const REFETCH_TIME = 2000;

const useRefetchPayment = () => {
  const { getPaymentRequest, setPaymentRequest } = usePaymentRequestProvider();

  const paymentRequest = useMemo(() => getPaymentRequest(), [getPaymentRequest]);

  const fetchPaymentRequest = useCallback(async () => {
    const response = await api.get(`/payment_requests/${paymentRequest?.id}`);
    if (response.status !== 200) {
      throw new Error('Failed to fetch payment request');
    }
    return response;
  }, [paymentRequest?.id]);

  const refetchIntervalQuery = useCallback((data: any) => {
    const payment: PaymentRequest_interface = data?.state?.data?.data || data?.state?.data;

    if (!payment) return REFETCH_TIME;

    const status: PaymentRequestStatus | undefined = payment.status;

    if (status === 'pending') {
      return REFETCH_TIME; // Continuar refetch cada 2 segundos si no está pagado
    }
    setPaymentRequest(payment);

    return false;
  }, [setPaymentRequest]);

  useQuery({
    queryKey: ['paymentRequest', paymentRequest?.id],
    enabled: !!paymentRequest?.id,
    retry: 3,
    queryFn: fetchPaymentRequest,
    refetchInterval: refetchIntervalQuery,
  });

  return;
};

export default useRefetchPayment;