import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { createUrlError, createUrlResponse, createUrlSuccess } from '@/helpers/navigate';
import { isPaymentExpired, isPaymentSuccess } from '@/helpers/payment.multiuse';
import { PaymentRequest_interface } from '@/interfaces/transaction.interface';

interface UseVerifyStatusPayment_interface{
  verifyPaymentStatus: (payment: PaymentRequest_interface | null) => void
}

const useVerifyStatusPayment = (): UseVerifyStatusPayment_interface => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();

  const verifyPaymentStatus = (payment: PaymentRequest_interface | null): void => {

    if(!payment || !params.slug) return;

    const isStatic = searchParams.get('static') === 'true';

    if(!payment.id) return;

    if(isPaymentSuccess(payment)){
      navigate(createUrlSuccess({id: payment.id, slug: params.slug}));
      return;
    }

    if(isPaymentExpired(payment) && !isStatic){
      navigate(createUrlResponse({id: payment.id, slug: params.slug}));
      return;
    }

    return;
  };

  return({verifyPaymentStatus});

};

export default useVerifyStatusPayment;