import { useMemo } from 'react';

import { isDevelop } from '@/helpers/global.state';

const useAppMetadata = () => {

  return useMemo(
    () => ({
      name: 'SimpleFi',
      description: 'Get paid in crypto with the lowest fees',
      url: isDevelop() ? 'https://develop.pagar.simplefi.tech' : 'https://www.pagar.simplefi.tech',
      logoUrl: 'https://simplefi.s3.us-east-2.amazonaws.com/icon.png',
    }),
    []
  );
};

export default useAppMetadata;