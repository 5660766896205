import { Flex, Separator, Skeleton, Text } from '@radix-ui/themes';
import { useTranslation } from 'react-i18next';

import useApi from '@/hooks/useApi';
import { CurrenciesApi_interface } from '@/interfaces/token.interface';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';

const RateInfo = () => {
  const { useGetRateForCoin } = useApi();
  const { getCurrencyForToken } = usePaymentMethodProvider();
  const { getAmount, getCurrency } = usePaymentRequestProvider();
  const { t } = useTranslation();
  
  const token: CurrenciesApi_interface | null = getCurrencyForToken();
  const currency = getCurrency();
  const paymentAmount = getAmount();

  const queryRateForCoin = useGetRateForCoin(token ? token.ticker : null);
  
  if(token){
    const rate = queryRateForCoin.data?.data[token.ticker.toUpperCase()];
    const amount = paymentAmount ? paymentAmount / rate : 0;

    return (
      <Flex direction={'column'} gap={'4'} width={'100%'}>
        <Skeleton loading={!queryRateForCoin.isSuccess}>
          {
            currency !== 'USD' && (
              <RowInfo
                title={t('Conversión')}
                value={`1 ${token.ticker.toUpperCase()} ≈ ${rate?.toLocaleString('es')} ${currency.toUpperCase()}`}
              />
            )
          }
          <RowInfo title={t('Monto')} value={`≈ ${amount.toFixed(token.decimals)} ${token.ticker.toUpperCase()}`}/>
        </Skeleton>
        <Separator size={'4'} color='violet'/>
      </Flex>
    );
  }
};

interface RowInfo_interface {
  title: string;
  value: string;
}

const RowInfo = ({title, value}: RowInfo_interface) => {
  return(
    <Flex justify={'between'}>
      <Text weight={'medium'} size={'2'}>{title}</Text>
      <Text weight={'medium'} size={'2'}>{value}</Text>
    </Flex>
  );
};

export default RateInfo;