import {  Flex } from '@radix-ui/themes';
import { ReactNode } from 'react';
interface ScreenWrapper_interface {
  children: ReactNode,
  loading?: boolean
}

const ScreenWrapper = ({children}: ScreenWrapper_interface) => {

  return (
    <Flex width={'100dvw'} height={'100dvh'} justify={'center'}>
      <div style={{ maxWidth: '420px', padding: '21px', height: '100%', width: '100%' }}>
        {children}
      </div>
    </Flex>
  );
};

export default ScreenWrapper;