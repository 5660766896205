import { Collapse } from '@mui/material';
import { Box, Button, ChevronDownIcon, Flex, Text } from '@radix-ui/themes';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaWhatsapp } from 'react-icons/fa6';
import { useRouteLoaderData } from 'react-router-dom';

import SendTransactionHash from '@/components/SendTransactionHash/SendTransactionHash';
import { Merchant_interface } from '@/interfaces/merchant.interfaces';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';

const BottomSupport = () => {
  const [open, setOpen] = useState(false);
  const { getToken } = usePaymentMethodProvider();
  const merchant: Merchant_interface = useRouteLoaderData('root') as Merchant_interface;
  const { t } = useTranslation();

  const handleClickSupport = (e: any) => {
    e.stopPropagation();
    const number = '5491149697137';
    const url = `https://wa.me/${number}`;
    window.location.href = url;
    return;
  };

  const currency = merchant.currencies.find(curr => curr.ticker === getToken());
  const isEVM = currency ? currency.evm_compatible : false;

  return (
    <Flex 
      justify={'center'} 
      direction={'column'} 
      onClick={() => setOpen(prev => !prev)}
      style={{ 
        width: '100%',
        position: 'absolute',
        bottom: 0, 
        left: 0,
        backgroundColor: '#FFF',
        borderTop: '1px solid rgba(0,0,0,.3)',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        padding: '12px',
      }}
    >
      <Text align={'center'}>{t('¿Tenés algún problema?')}</Text>
      <Collapse in={open}>
        {
          isEVM && (
            <RowSupport title={t('¿No ves el pago aprobado? Compartinos el hash de la transacción.')}>
              <SendTransactionHash/>
            </RowSupport>
          )
        }
        <RowSupport title={t('Chateá con nosotros.')}>
          <Button 
            style={{width: '100%'}} 
            variant='outline'
            onClick={handleClickSupport}
          >
            <FaWhatsapp/> {t('Envianos tu consulta')}
          </Button>
        </RowSupport>

      </Collapse>
    </Flex>
  );
};

const RowSupport = ({children, title}: {children: ReactNode, title: string}) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e: any) => {
    e.stopPropagation();
    setOpen(prev => !prev);
  };

  return(
    <Flex 
      width={'100%'} 
      direction={'column'} 
      onClick={handleClick} 
      style={{
        margin: '12px 0',
        padding:'3px',
        borderBottom: '1px solid rgba(0,0,0,.3)'
      }}
    >
      <Flex justify={'between'} align={'center'}>
        <Text align={'left'}>{title}</Text>
        <ChevronDownIcon/>
      </Flex>
      <Collapse in={open}>
        <Box style={{padding: '18px 12px'}}>
          {children}
        </Box>
      </Collapse>
    </Flex>
  );
};

export default BottomSupport;