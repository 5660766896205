import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getContract, prepareContractCall, prepareTransaction, sendAndConfirmTransaction } from 'thirdweb';
import { useActiveAccount } from 'thirdweb/react';

import { ERC20_ABI } from '@/assets/Constants/ABIs/ERC20_ABI';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';
import { useUserStatusProvider } from '@/providers/UserStatus.provider';
import { TransferData } from '@/types/transaction.types';

import useClient from '../useClient';
import useCustomNavigation from '../useCustomNavigation';
import useSwitchChain from '../Wallet/useSwitchChain';


interface useTransfer_interface {
  transfer: () => void
}

const useTransfer = (): useTransfer_interface => {
  const { getChain } = usePaymentMethodProvider();
  const { getTransferData, getStatusPayment } = usePaymentRequestProvider();
  const client = useClient();
  const activeAccount = useActiveAccount();
  const { switchChain } = useSwitchChain();
  const { setStatus } = useUserStatusProvider();
  const { navigateToPaymentProcess, backToPayment } = useCustomNavigation();

  const transfer = async () => {
    global.Buffer = global.Buffer || require('buffer').Buffer;

    const [transferData, statusPayment] = [getTransferData(), getStatusPayment()];
    
    if(!transferData || !statusPayment) {
      setStatus('pending');
      return;
    }

    if (statusPayment === 'pending') {
      const decimals = transferData.decimals;
      const amount = BigInt(Math.round(transferData.price_details.final_amount * Math.pow(10, decimals)));

      try {
        const switched = await switchChain();

        if (!switched) {
          setStatus('pending');
          return;
        }

        setStatus('in_process');
        navigateToPaymentProcess();

        if (transferData.contract_address) {
          await _transferToken(transferData, amount);
        } else {
          await _transferMainnet(transferData, amount);
        }
      } catch (e: any) {
        // setStatus(STATUS_PAYMENT.pending);
        if (e.message.includes('transfer amount exceeds balance')) {
          toast.error('No hay fondos suficientes para realizar la transacción.');
        }
        console.error('error transfer', e.message, e.code);
        setStatus('pending');
        backToPayment();
      }
    }
  };

  const _transferToken = async (transferData: TransferData, amount: bigint) => {
    const [chain, contract_address] = [getChain(), transferData.contract_address];

    if(!chain || !contract_address || !activeAccount) return;

    const contract = getContract({
      client,
      chain: chain,
      address: contract_address,
      abi: ERC20_ABI as any, //TODO: type for ERC20_ABI
    });

    const contractCall = prepareContractCall({
      contract,
      method: 'function transfer(address _to, uint256 _value)',
      params: [transferData.address, amount],
    });

    await sendAndConfirmTransaction({
      transaction: contractCall,
      account: activeAccount,
    });

  };

  const _transferMainnet = async (transferData: TransferData, amount: bigint) => {
    const [chain, contract_address] = [getChain(), transferData.contract_address];

    if(!chain || !contract_address || !activeAccount) return;

    const tx = prepareTransaction({
      to: transferData.address,
      value: amount,
      chain: chain,
      client,
    });

    await sendAndConfirmTransaction({
      transaction: tx,
      account: activeAccount,
    });
  };

  return ({
    transfer
  });
};

export default useTransfer;