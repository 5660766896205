import { Flex, Grid } from '@radix-ui/themes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@/components/Button/Button';
import Title from '@/components/Headers/Title';
import InputAmount from '@/components/Input/InputAmount';
import ScreenWrapper from '@/components/ScreenWrapper/ScreenWrapper';
import useApi from '@/hooks/useApi';
import useCustomNavigation from '@/hooks/useCustomNavigation';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';

import ConvertionRate from './ConvertionRate';

const Amount = () => {
  const [searchParams] = useSearchParams();
  const { useGetRateForCoin } = useApi();
  const { data, isLoading } = useGetRateForCoin('USDT');
  const [amount, setAmountState] = useState<string>('');
  const { setAmount, getPaymentRequest, getCurrency, getAmount, setPaymentRequest } = usePaymentRequestProvider();
  const navigate = useNavigate();
  const isStatic = searchParams.get('static') === 'true';
  const currency = getCurrency();
  const { navigateToStatic } = useCustomNavigation();
  const { t } = useTranslation();

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if(value && value.length < 12 && value.length >= 0){
      setAmountState(value.replace(',', '.'));
      return;
    }
    if(!value){
      setAmountState('');
      return;
    }
  };

  const isDisabledContinue = !amount || amount.length === 0 || !Number(amount) || Number(amount) <= 0;

  const handleClickContinue = () => {
    if(isDisabledContinue) return;
    const payment = getPaymentRequest();
    const numberAmount = Number(amount);
    const paymentAmount = getAmount();

    if(payment && (numberAmount !== paymentAmount)){
      setPaymentRequest(null);
    }

    setAmount(Number(amount));
    navigate(`amount/${amount}?currency=${currency}`);
  };

  const handleKeyDown = (event: any) => {
    if(event.key === 'Enter'){
      handleClickContinue();
    }
  };


  if(isStatic){
    navigateToStatic();
    return;
  }
  
  return (
    <ScreenWrapper>
      <Grid columns={'1'} rows={'auto 1fr auto'} gap={'3'} height={'100%'} onKeyDown={handleKeyDown}>
        <Title title={t('Pagar con Crypto')} subtitle='Powered by SimpleFi'/>

        <Flex style={{flex: 2}} direction={'column'} gap={'4'} justify={'center'} align={'center'}>
          <InputAmount value={amount} setValue={handleChangeValue} placeholder={'0,0'} currency={currency}/>
          {
            currency !== 'USD' && (
              <ConvertionRate rate={amount ? (Number(amount) / data?.data?.USDT) : 0} loading={isLoading}/>
            )
          }
        </Flex>

        <Button size={'3'} style={{width: '100%'}} onClick={handleClickContinue} disabled={isDisabledContinue}>{t('Continuar')}</Button>
      </Grid>

    </ScreenWrapper>
  );
};

export default Amount;