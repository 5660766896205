export default {
  translation: {
    'Moneda': 'Moneda',
    'Seleccioná una moneda': 'Seleccioná una moneda',
    'Seleccionar moneda': 'Seleccionar moneda',
    'Red': 'Red',
    'Seleccioná una red': 'Seleccioná una red',
    'Seleccionar red': 'Seleccionar red',
    '% extra charge': '% Cargo extra',
    'Wallet': 'Wallet',
    'Seleccioná una wallet': 'Seleccioná una wallet',
    'Seleccionar wallet': 'Seleccionar wallet',
    'disclaimers': {
      'ripio': '* Tope de reintegro de 20.000 pesos mensuales por usuario de Ripio.'
    },
    'Conectado a': 'Conectado a',
    'Desconectar': 'Desconectar',
    'Conversión': 'Conversión',
    'Monto': 'Monto',
    'El email ingresado no es válido.': 'El email ingresado no es válido.',
    'Ir a pagar': 'Ir a pagar',
    'Conectar Wallet': 'Conectar Wallet',
    'Pagar': 'Pagar',
    'Wallet default de tu dispositivo': 'Wallet default de tu dispositivo',
    'Transferí desde una wallet': 'Transferí desde una wallet',
    'Enviando...': 'Enviando...',
    'Aguardando confirmación del pago.': 'Aguardando confirmación del pago.',
    'Este proceso puede tardar unos segundos.': 'Este proceso puede tardar unos segundos.',
    'Cancelar': 'Cancelar',
    'Pagar con Crypto':'Pagar con Crypto',
    'Continuar': 'Continuar',
    'Pago expirado': 'Pago expirado',
    'Nuevo pago': 'Nuevo pago',
    '¿Tenés algún problema?': '¿Tenés algún problema?',
    '¿No ves el pago aprobado? Compartinos el hash de la transacción.': '¿No ves el pago aprobado? Compartinos el hash de la transacción.',
    'Chateá con nosotros.': 'Chateá con nosotros.',
    'Envianos tu consulta': 'Envianos tu consulta',
    'Pagaste': 'Pagaste',
    'El pago fue realizado con éxito': 'El pago fue realizado con éxito',
    'a': 'a',
    'Compartí tu experiencia en': 'Compartí tu experiencia en',
    'El monto ingresado no es válido.': 'El monto ingresado no es válido.',
    'Enviá el monto exacto a la dirección indicada aquí abajo.': 'Enviá el monto exacto a la dirección indicada aquí abajo.',
    'copiado al portapapeles': 'copiado al portapapeles',
    'Products': 'Productos',
    'cart': {
      'add': 'Agregar al carrito',
      'drawer': {
        'title': 'Mi carrito'
      }
    },
    'static': {
      'order_not_found': {
        'title': 'No se creó la orden.',
        'subtitle': 'Avisale al cajero que estas listo para pagar.',
        'button': 'Ya le avisé'
      }
    },
    'actions': {
      'Cerrar': 'Cerrar'
    },
    'process':{
      'payment':{
        'steps':{
          '1': '1. Escaneá el QR desde la cámara de tu celular.',
          '2': '2. Confirmá la transacción desde tu wallet.',
          '3': '3. Volvé a esta pantalla para verificar la confirmación del pago.'
        }
      }
    }
  }
};