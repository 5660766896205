import { ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteLoaderData } from 'react-router-dom';

import { WALLETS } from '@/assets/Constants/WALLETS/WALLETS';
import Select from '@/components/Select/Select';
import { OptionSelect } from '@/components/Select/select.interface';
import { isMobile } from '@/helpers/global.state';
import { Chain } from '@/interfaces/chain.interfaces';
import { Merchant_interface } from '@/interfaces/merchant.interfaces';
import { Wallet } from '@/interfaces/wallet.interface';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';
import { TokenSymbol_Type } from '@/types/token.types';
import { WalletId_Type } from '@/types/wallet.types';

import { disclaimers } from './Constants';

const formatWalletOption = (wallets: Wallet[]) => {
  return wallets.map((w: Wallet) => ({label: w.name, value: w.id, url_icon: w.icon }) as OptionSelect);
};

const DEFAULT_WALLETS: OptionSelect[] = formatWalletOption(WALLETS);

const getWalletsForChains = (chain: Chain) => {
  return WALLETS.filter((w: Wallet) => w.supportsChains.includes(chain));
};

const getWalletForToken = (token: TokenSymbol_Type) => {
  return WALLETS.filter((w: Wallet) => {
    if(w.supportsCustomTokens) return w;

    const walletChain = w.supportsChains.find((c) => w.supportsTokens?.[c.id]?.includes(token));

    if(walletChain) return w;
    
  });
};

const getWalletsForBTC = () => {
  return WALLETS.filter((w: Wallet) => {    
    if(w.id === 'other') return w;
    
    if(w.supportsCustomTokens) return;

    const walletChain = w.supportsChains.find((c) => w.supportsTokens?.[c.id]?.includes('BTC'));
    if(walletChain) return w;
    
  });
};

const getWalletsForBCH = () => {
  return WALLETS.filter((w: Wallet) => {
    if(w.id === 'other') return w;
    
    if(w.supportsCustomTokens) return;

    const walletChain = w.supportsChains.find((c) => w.supportsTokens?.[c.id]?.includes('BCH'));
    if(walletChain) return w;
    
  });
};

const SelectWallet = ({disabled}: {disabled?: boolean}): ReactNode => {
  const { setWallet, getWallet, getChain, getToken, isPaymentCardUSD } = usePaymentMethodProvider();
  const merchant: Merchant_interface = useRouteLoaderData('root') as Merchant_interface;
  const { t } = useTranslation();
  
  const wallet: Wallet | null = getWallet();
  const token = getToken();
  const chain = getChain();

  const OptionsWallet = useMemo(() => {
    if(!token && !chain) return DEFAULT_WALLETS;

    if(chain && !token){
      return formatWalletOption(getWalletsForChains(chain));
    }

    if(token && !chain) {
      return formatWalletOption(getWalletForToken(token));
    }

    if(!token || !chain) return [];

    if(token.toUpperCase() === 'BTC'){
      const walletsForBTC = getWalletsForBTC();
      return formatWalletOption(walletsForBTC);
    }

    if(token.toUpperCase() === 'BCH'){
      const walletsForBCH = getWalletsForBCH();
      return formatWalletOption(walletsForBCH);
    }

    const walletsForChain = getWalletsForChains(chain);
    const walletsForToken = getWalletForToken(token);
    const wallets = walletsForChain.filter(w1 => walletsForToken.some(w2 => w1.id === w2.id)); //interseccion de wallets
    return formatWalletOption(wallets);
    
  }, [chain, token]);

  useEffect(() => {
    if(!wallet && merchant.campaigns?.find(c => c.name === 'ripio' && c.discount > 0)){
      setWallet('ripio');
    }

    if(isPaymentCardUSD){
      setWallet(null);
      return;
    }

    if(OptionsWallet.length === 1){ //Si es el unico Token, lo pre-selecciono
      setWallet(OptionsWallet[0].value as WalletId_Type);
      return;
    }
    if(wallet && !OptionsWallet.find(op => op.value === wallet.id)){ //si el Token seleccionado anteriormente no esta en los actuales
      setWallet(null);
      return;
    }
  }, [chain, wallet, token, setWallet, OptionsWallet, isPaymentCardUSD]);
  
  if(!isPaymentCardUSD){
    return (
      <Select.Disclaimer
        title={t('Wallet')}
        label={t('Seleccioná una wallet')}
        options={OptionsWallet}
        setValue={(w) => setWallet(w)}
        value={wallet?.id ?? ''}
        placeholder={t('Seleccionar wallet')}
        disabled={disabled}
        optionsBadge={merchant?.campaigns}
        disclaimers={disclaimers}
      />
    );
  }
};

export default SelectWallet;