import { defineChain } from 'thirdweb';

import { isDevelop } from '@/helpers/global.state';
import { Chain } from '@/interfaces/chain.interfaces';

const IconsProps = {
  format: 'url',
  width: 24,
  height: 24
};

export const ETHEREUM: Chain = defineChain({
  id: 1,
  name: 'Ethereum Mainnet',
  shortName: 'Ethereum',
  rpc: 'https://go.getblock.io/ac0ac6581b2044f3beaa9f08d3b67439',
  icon: {
    url: 'https://simplefi.s3.us-east-2.amazonaws.com/logo_eth.png',
    ...IconsProps
  },
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
});

export const OPTIMISM: Chain = defineChain({
  id: 10,
  name: 'Optimism',
  shortName: 'Optimism',
  rpc: 'https://go.getblock.io/eb1029c611e54e459b1feecea77d8aa5',
  icon: {
    url: 'https://simplefi.s3.us-east-2.amazonaws.com/logo_op.png',
    ...IconsProps
  },
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
});

export const LACHAIN: Chain = defineChain({
  id: 274,
  name: 'LaChain',
  shortName: 'LaChain',
  rpc: 'https://rpc1.mainnet.lachain.network',
  icon: {
    url: 'https://simplefi.s3.us-east-2.amazonaws.com/logo_lachain.png',
    ...IconsProps
  },
  nativeCurrency: {
    decimals: 18,
    name: 'LaCoin',
    symbol: 'LAC',
  },
});

export const POLYGON: Chain = defineChain({
  id: 137,
  name: 'Polygon Mainnet',
  shortName: 'Polygon',
  rpc: 'https://go.getblock.io/d899bf2097b94aa8ae491057a512cc95',
  slug: 'polygon',
  icon: {
    url: 'https://simplefi.s3.us-east-2.amazonaws.com/logo_polygon.png',
    ...IconsProps
  },
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
});

export const ROOTSTOCK: Chain = defineChain({
  id: 30,
  name: 'Rootstock',
  shortName: 'Rootstock',
  rpc: 'https://mycrypto.rsk.co/',
  icon: {
    url: 'https://simplefi.s3.us-east-2.amazonaws.com/logo_rootstock.png',
    ...IconsProps
  },
  nativeCurrency: {
    decimals: 18,
    name: 'RSK',
    symbol: 'rbtc',
  },
  slug: 'rsk',
});

export const SEPOLIA: Chain = defineChain({
  id: 11155111,
  name: 'Sepolia Test Network',
  shortName: 'Sepolia',
  slug: 'sepolia',
  icon: {
    url: 'https://simplefi.s3.us-east-2.amazonaws.com/logo_eth.png',
    ...IconsProps
  },
  nativeCurrency: {
    name: 'SepoliaETH',
    symbol: 'SepoliaETH',
    decimals: 6,
  },
  rpc: 'https://rpc2.sepolia.org',
  testnet: true,
});

export const BASE: Chain = defineChain({
  id: 8453,
  name: 'Base Mainnet',
  shortName: 'Base',
  slug: 'base',
  icon: {
    url: 'https://simplefi.s3.us-east-2.amazonaws.com/logo_base.png',
    ...IconsProps
  },
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpc: 'https://api.developer.coinbase.com/rpc/v1/base/j-PLwlqenICBtIVLRypL3gHUlhSy7SQo',
});

export const ZKSYNC: Chain = defineChain({
  id: 324,
  name: 'ZKsync Era Mainnet',
  shortName: 'ZKsync Era',
  slug: 'zksync',
  icon: {
    url: 'https://simplefi.s3.us-east-2.amazonaws.com/logo_zksync.png',
    ...IconsProps
  },
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpc: 'https://go.getblock.io/8afdbc5f922b47b7ba0611e1678c2542',
});

export const LIGHTING: Chain = {
  id: 0,
  name: 'Lightning Network',
  short_name: 'Lightning Network',
  icon: {
    url: 'https://simplefi.s3.us-east-2.amazonaws.com/logo_ln.png',
    ...IconsProps
  },
  rpc: '',
  nativeCurrency: {
    name: 'BTC',
    symbol: 'BTC',
    decimals: 18,
  },
};

export const BCH: Chain = {
  id: -1,
  name: 'Bitcoin Cash',
  short_name: 'Bitcoin Cash',
  icon: {
    url: 'https://simplefi.s3.us-east-2.amazonaws.com/logo_bch.png',
    ...IconsProps
  },
  rpc: '',
  nativeCurrency: {
    name: 'BCH',
    symbol: 'BCH',
    decimals: 8,
  },
};

export const STRIPE: Chain = {
  id: -2,
  name: 'Card',
  short_name: 'Credit & Debit Card',
  icon: {
    url: 'https://simplefi.s3.us-east-2.amazonaws.com/logo_stripe.png',
    ...IconsProps
  },
  rpc: '',
  nativeCurrency: {
    name: 'USD',
    symbol: 'USD',
    decimals: 8,
  },
};

export const SUPPORTED_CHAINS: Chain[] = [
  LIGHTING,
  LACHAIN, 
  POLYGON, 
  ROOTSTOCK,
  ETHEREUM, 
  OPTIMISM, 
  BASE, 
  ZKSYNC,
  BCH,
  STRIPE,
  ...(isDevelop() ? [SEPOLIA] : [])
];

