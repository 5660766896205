interface createUrl_interface {
  slug: string;
  id: string
}

export const createUrlSuccess = ({slug, id}: createUrl_interface): string => {
  return `/${slug}/payment/${id}/success`;
};

export const createUrlProcess = ({slug, id}: createUrl_interface): string => {
  return `/${slug}/payment/${id}/process`;
};

export const createUrlError =  ({slug, id}: createUrl_interface): string => {
  return `/${slug}/payment/${id}/error`;
};

export const createUrlResponse = ({slug, id}: createUrl_interface): string => {
  return `/${slug}/payment/${id}/response`;
};