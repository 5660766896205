import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';

const useControllerParams = (): void => { //TODO: es correcto hacer esto?
  const { setAmount, getAmount } = usePaymentRequestProvider();
  const params = useParams();

  const amount = getAmount();

  useEffect(function controlAmountParams () {
    if(params.amount && !amount){
      setAmount(Number(params.amount));
    }
  }, [params.amount, amount, setAmount]);
};

export default useControllerParams;