import { Flex, Text, TextField } from '@radix-ui/themes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDebounce from '@/hooks/useDebounce';
import { useCartContext } from '@/providers/Cart.provider';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';

const InputEmailProduct = () => {
  const { setReference, getReference } = usePaymentRequestProvider();
  const defaultEmail = getReference()?._email;
  const [email, setEmail] = useState(defaultEmail ? defaultEmail : '');
  const [error, setError] = useState('');
  const { getProduct, getCart } = useCartContext();
  const { t } = useTranslation();

  const debouncedValue = useDebounce(email, 300);
  const product = getProduct();

  const validateEmail = () => {
    if(!email || email.length < 1) return false;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!regex.test(email)){
      setError(t('El email ingresado no es válido.'));
      return false;
    }
    setError('');
    return true;
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setEmail(value);
  };

  useEffect(() => {
    if(!product) return;
    const isValid = validateEmail();
    if(isValid){
      setReference({_email: email});
      return;
    }

    setReference({_email: ''});
  }, [debouncedValue]);

  const requireEmail = (product && product.require_email) || getCart()?.some(item => item.require_email);

  if(requireEmail){
    return (
      <Flex direction={'column'}>
        <TextField.Root placeholder="Email" type="email" onChange={handleChange} value={email}/>
        <Text size={'1'} weight={'light'} color='red'>{error}</Text>
      </Flex>
    );
  }
};

export default InputEmailProduct;