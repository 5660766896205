import 'react-toastify/dist/ReactToastify.min.css';

import { Outlet, useNavigation } from 'react-router-dom';
import { Bounce, ToastContainer } from 'react-toastify';

import ConsoleLogger from './components/Logger/ConsoleLogger';
import { isDevelop } from './helpers/global.state';
import Loader from './pages/Process/Loader';

function App() {
  const navigation = useNavigation();
  const develop = isDevelop();

  if(navigation.state === 'loading'){
    return(
      <Loader/>
    );
  }

  return (
    <>
      <Outlet/>
      {/* {
        develop && (
          <ConsoleLogger/>
        )
      } */}
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        limit={2}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="light"
        transition={Bounce}
        style={{marginBottom: '18px', padding: '0 15px', fontWeight: '500'}}
      />
    </>
  );
}

export default App;
