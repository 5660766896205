import { useQueryClient } from '@tanstack/react-query';

import api from '@/api';
import { PaymentRequest_interface } from '@/interfaces/transaction.interface';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';

import useCustomNavigation from '../useCustomNavigation';

interface UseGetPaymentRequest_interface{
  fetchPaymentRequest: (id?: string) => Promise<PaymentRequest_interface | undefined>
} 
export const useGetPaymentRequest = (): UseGetPaymentRequest_interface => {
  const { setPaymentRequest, getPaymentRequest } = usePaymentRequestProvider();
  const { navigateError } = useCustomNavigation();
  const queryClient = useQueryClient();
  
  const paymentRequest = getPaymentRequest();

  const fetchPaymentRequest = async (id?: string): Promise<PaymentRequest_interface | undefined> => {
    if(paymentRequest) return paymentRequest;
  
    if(id){
      try {
        const paymentRequest = await queryClient.fetchQuery({
          queryKey: ['paymentRequest', id],
          queryFn: () => api.get(`/payment_requests/${id}`),
        });

        setPaymentRequest(paymentRequest.data);
        return paymentRequest.data;
      } catch (error) {
        navigateError();
      }
    }
  };

  return({
    fetchPaymentRequest
  });
};