import { Flex, Text } from '@radix-ui/themes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import QRCodeComponent from '@/components/QRCode/QRCodeComponent';
import { isMobile } from '@/helpers/global.state';
import { getUrlRequestFromTransaction } from '@/helpers/web3.multiuse';
import { useGetPaymentRequest } from '@/hooks/Payment/useGetPaymentRequest';
import useRefetchPayment from '@/hooks/Payment/useRefetchPayment';
import { Wallet } from '@/interfaces/wallet.interface';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';

import InfoPaymentCopy from './Components/InfoPaymentCopy';
import PaymentProcessComponent from './Components/PaymentProcessComponent';
import RowPaymentInfo from './Components/RowPaymentInfo';

const ProcessPayment = () => {
  const params = useParams();
  const { getWallet } = usePaymentMethodProvider();
  const { fetchPaymentRequest } = useGetPaymentRequest();
  const { t } = useTranslation();

  const wallet = getWallet();

  useMemo(() => fetchPaymentRequest(params.payment_id), [params.payment_id]);
  
  useRefetchPayment();

  if(wallet?.connectMethod === 'WithoutConnecting'){
    return(
      <PayWithoutConnecting  />
    );
  }

  if(wallet?.connectMethod === 'DeepLink' && !isMobile()){
    return <PayWithDeppLink wallet={wallet}/>;
  }

  return (
    <PaymentProcessComponent>
      <InfoPaymentProcess/>
    </PaymentProcessComponent>
  );
};

const PayWithDeppLink = ({wallet}: {wallet: Wallet | null}) => {
  const { getTransactionForTokenInChain } = usePaymentRequestProvider();

  const transaction = getTransactionForTokenInChain();

  if(!transaction || !wallet || !wallet.deeplinkFunction) return;

  const deeplinkURL = wallet.deeplinkFunction(transaction);

  if(deeplinkURL){
    return(
      <PaymentProcessComponent>
        <QRCodeComponent value={deeplinkURL}/>
        <InfoPaymentProcess/>
      </PaymentProcessComponent>
    );
  }
};

const PayWithoutConnecting = () => {
  const { getTransferData } = usePaymentRequestProvider();
  const transaction = getTransferData();
  const { t } = useTranslation();
  const urlTransactionAddress = useMemo(() => getUrlRequestFromTransaction(transaction), [transaction]);

  if(!transaction) return;

  const title = transaction.ticker === 'BTC' ? 'Invoice' : 'Address';

  if(!isMobile() && transaction.chain_id >= 0){ //Si esta en desktop y la tx no es BTC - BCH

    return(
      <PaymentProcessComponent>
        {
          urlTransactionAddress && (
            <QRCodeComponent value={urlTransactionAddress} />
          )
        }
        <Flex direction={'column'} align={'center'} gap={'6'}>
          <RowPaymentInfo title={title} subtitle={transaction.address} hidde={true}/>
          <RowPaymentInfo title={t('Monto')} subtitle={transaction.price_details?.final_amount?.toString()} ticker={transaction.ticker}/>
        </Flex>
      </PaymentProcessComponent>
    );
  }

  return(
    <PaymentProcessComponent>
      <Flex direction={'column'} align={'center'} gap={'6'}>
        <InfoPaymentCopy/>
        <RowPaymentInfo title={title} subtitle={transaction.address} hidde={true}/>
        <RowPaymentInfo title={t('Monto')} subtitle={transaction.price_details?.final_amount?.toString()} ticker={transaction.ticker}/>
      </Flex>
    </PaymentProcessComponent>
  );
};

const InfoPaymentProcess = () => {
  const { t } = useTranslation();

  if(isMobile()){
    return(
      <Flex direction={'column'} align={'center'}>
        <Text weight={'medium'} size={'4'}>{t('Enviando...')}</Text>
        <Text color='violet' align={'center'} weight={'medium'}>{t('Aguardando confirmación del pago.')}</Text>
        <Text color='violet' align={'center'} weight={'medium'}>{t('Este proceso puede tardar unos segundos.')}</Text>
      </Flex>
    );
  }

  return(
    <Flex direction={'column'} align={'start'} gap={'3'}>
      <Text color='violet' align={'left'} weight={'medium'}>{t('process.payment.steps.1')}</Text>
      <Text color='violet' align={'left'} weight={'medium'}>{t('process.payment.steps.2')}</Text>
      <Text color='violet' align={'left'} weight={'medium'}>{t('process.payment.steps.3')}</Text>
    </Flex>
  );
};

export default ProcessPayment;