import { CurrenciesApi_interface } from '@/interfaces/token.interface';

import { STRIPE } from './CHAINS';

export const USD: CurrenciesApi_interface = {
  ticker: 'USD',
  address: null,
  chain_id: -2,
  chain_logo: STRIPE.icon?.url ?? '',
  chain_name: 'Credit & Debit Card',
  contract_address: null,
  currency_logo: 'https://simplefi.s3.us-east-2.amazonaws.com/logo_usdcard.png',
  decimals: 8,
  enabled: true,
  evm_compatible: false,
  address_needed: false
};