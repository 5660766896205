import { Flex, Grid } from '@radix-ui/themes';
import { useEffect, useMemo } from 'react';
import { useParams, useRouteLoaderData, useSearchParams } from 'react-router-dom';

import { USD } from '@/assets/Constants/TOKENS';
import ConnectedWallet from '@/components/ConnectedWallet/ConnectedWallet';
import ScreenWrapper from '@/components/ScreenWrapper/ScreenWrapper';
import { isPaymentExpired } from '@/helpers/payment.multiuse';
import { useGetPaymentRequest } from '@/hooks/Payment/useGetPaymentRequest';
import { useGetProduct } from '@/hooks/Payment/useGetProduct';
import useCustomNavigation from '@/hooks/useCustomNavigation';
import { Merchant_interface } from '@/interfaces/merchant.interfaces';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';
import { useUserStatusProvider } from '@/providers/UserStatus.provider';
import { WalletId_Type } from '@/types/wallet.types';

import Header from '../../components/Headers/Header';
import Loader from '../Process/Loader';
import SelectChain from './Chain.select';
import ButtonPayment from './Components/ButtonPayment';
import InputEmailProduct from './Components/InputEmailProduct';
import RateInfo from './Components/RateInfo';
import SelectToken from './Token.select';
import SelectWallet from './Wallet.select';

const Payment = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { setChain, setToken, setWallet } = usePaymentMethodProvider();
  const { getPaymentRequest, setAmount } = usePaymentRequestProvider();
  const merchant: Merchant_interface = useRouteLoaderData('root') as Merchant_interface;
  const { navigateToResponse, navigateError} = useCustomNavigation();
  const { setStatus } = useUserStatusProvider();

  const { fetchPaymentRequest } = useGetPaymentRequest();
  const { fetchProduct } = useGetProduct();

  useMemo(() => fetchPaymentRequest(params.payment_id), [params.payment_id]);
  useMemo(() => fetchProduct(params.product_id), [params.product_id]);

  const paramsTicker = searchParams.get('ticker');
  const paramsChainId = searchParams.get('chain_id');
  const paramsWallet = searchParams.get('wallet');
  const paymentRequest = getPaymentRequest();
  const hasParamAmount = params.amount;
  const validParamAmount = Number(params.amount);

  useEffect(() => {
    setStatus('pending');

    if(hasParamAmount && !validParamAmount){
      navigateError();
      return;
    }
    
    if(hasParamAmount){
      setAmount(validParamAmount);
    }

    if(isPaymentExpired(paymentRequest)){
      navigateToResponse();
      return;
    }
    
    if(paramsTicker || paramsChainId || paramsWallet){
      setWallet(paramsWallet as WalletId_Type);
      setToken(paramsTicker);
      setChain(Number(paramsChainId));
    }

  }, [paymentRequest]);

  const enabledCurrencies = useMemo(() => {
    const currencies = merchant.currencies.filter(currency => currency.enabled);
    if(merchant.stripe_credentials?.enabled){
      currencies.splice(2, 0, USD);
    }
    return currencies;
  }, [merchant.currencies, merchant.stripe_credentials]);

  if(hasParamAmount && !validParamAmount){
    return;
  }

  if(paymentRequest && isPaymentExpired(paymentRequest)) {
    return <Loader/>;
  }

  return (
    <ScreenWrapper>
      <Grid 
        rows={'1fr auto 2fr auto'}
        gap={'6'}
        height={'100%'}
        justify={'between'}
      >
        <div>
          <Header merchant={merchant} />
        </div>

        <Flex direction={'column'} gap={'4'}>
          <SelectToken currencies={enabledCurrencies} disabled={!!paramsTicker}/>
          <SelectChain currencies={enabledCurrencies} disabled={!!paramsChainId}/>
          <SelectWallet disabled={!!paramsWallet}/>
          <ConnectedWallet/>
        </Flex>

        <div>
          <RateInfo/>
        </div>

        <InputEmailProduct/>
        
        <ButtonPayment />

      </Grid>
    </ScreenWrapper>
  );
};

export default Payment;