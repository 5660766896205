import { Button, Flex } from '@radix-ui/themes';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import ScreenWrapper from '@/components/ScreenWrapper/ScreenWrapper';

const Mint = () => {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect_url');

  const openRedirect = () => {
    if(!redirect) return;
    window.location.href = redirect;
  };

  useEffect(() => {
    openRedirect();
  }, []);
  
  return (
    <ScreenWrapper>
      <Flex justify={'center'} width={'100%'} height={'100%'} align={'center'}>
        <Button onClick={openRedirect}>Open Link</Button>
      </Flex>
    </ScreenWrapper>
  );
};

export default Mint;