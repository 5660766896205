import { browserName, osName } from 'react-device-detect';
import { json } from 'react-router-dom';

import { getDeviceId, getSessionId, setSessionId } from '@/helpers/sessions';

import api from '.';

export const credentialsLoader = async (params, request) => {
  try{
    const url = new URL(request.url);
    const searchParams = url.searchParams;
    const session = searchParams.get('session');

    if(session){
      setSessionId(session);
      return true;
    }

    if(!params.slug) throw json({ msg: 'Debes ingresar un comercio' }, { status: 401 });

    const device_id = getDeviceId();
    const session_id = getSessionId();

    if(session_id){
      return true;
    }
    
    const sessionData = {
      device_id,
      browser: browserName,
      merchant: params.slug,
      device_os: osName,
      payment_request_id: params.payment_id
    };

    const response = await api.post('/sessions', sessionData);

    if(response.status !== 200 && response.status !== 201) throw json({ msg: `No se encontro el comercio ${params.slug} o es invalido.` }, { status: 404 });

    setSessionId(response.data.id);

    return true;
  }catch (e) {
    throw json({ msg: `No se encontro el comercio ${params.slug} o es invalido.` });
  }
};

export const merchantLoader = async (params) => {
  try{
    const response = await api.get(`/merchants/${params.slug}`);

    if(response.status !== 200) throw new Response('Not Found error merchant', { status: 404 });

    return response.data;
  }catch (e) {
    throw new Response('Not Found error merchant', { status: 404, ...e });
  }
};

export const rootLoader = async ({params, request}) => {
  try{
    await credentialsLoader(params, request);
    const merchant = await merchantLoader(params);
    return merchant;
  }catch (e){
    throw json({ msg: `No se encontro el comercio ${params.slug} o es invalido.` }, { status: 404 });
  }
};

export const chainsLoader = async () => {
  try{
    const response = await api.get('/chains');
    if(response.status === 200){
      return response.data;
    }
    return false;
  }catch (e) {
    throw new Response('Not Found error chains', { status: 404, ...e });
  }
};

export const loaderProducts = async ({...rest}) => {
  try{
    console.log('rest', rest);
    const response = await api.get('/products');
    if(response.status === 200){
      return response.data?.results;
    }
    return false;
  }catch (e){
    throw new Response('Not Found error products', { status: 404, ...e });

  }
};