import { useRouteLoaderData } from 'react-router-dom';

import api from '@/api';
import { isMobile } from '@/helpers/global.state';
import { getSessionId } from '@/helpers/sessions';
import { Merchant_interface } from '@/interfaces/merchant.interfaces';
import { CoinPaymentRequest_interface } from '@/interfaces/token.interface';
import { createPayment_interface, PaymentRequest_interface, TransactionModel_interface } from '@/interfaces/transaction.interface';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';
import { useUserStatusProvider } from '@/providers/UserStatus.provider';

import useCustomNavigation from '../useCustomNavigation';
import useConnection from '../Wallet/useConnection';
import useWallet from '../Wallet/useWallet';
import { useCreatePayment } from './useCreatePayment';
import useTransfer from './useTransfer';

interface UseControlPayment_interface {
  resolveConnectionMethod: (transaction?: TransactionModel_interface) => boolean;
  controlPayment: () => Promise<void>
}

const useControlPayment = (): UseControlPayment_interface => {
  const { 
    getAmount, 
    getPaymentRequest, 
    setPaymentRequest, 
    getTransactionForTokenInChain, 
    addTransactionForTokenChain,
    getCurrency,
    getReference
  } = usePaymentRequestProvider();
  const { getChain, getToken, getWallet, isPaymentCardUSD } = usePaymentMethodProvider();
  const { goToDeeplink, navigateToPaymentProcess } = useCustomNavigation();
  const { connectWallet } = useConnection();
  const { isConnected, isDisconnected } = useWallet();
  const { transfer } = useTransfer();
  const { setStatus } = useUserStatusProvider();
  const merchant: Merchant_interface = useRouteLoaderData('root') as Merchant_interface;
  
  const token = getToken();
  const coinsPayment: CoinPaymentRequest_interface = { chain_id: getChain()?.id, ticker: token } as CoinPaymentRequest_interface;

  const { mutateAsync: createPaymentRequest }  = useCreatePayment();

  //Esta funcion se encarga de resolver el connectMethod de la wallet seleccionada
  const resolveConnectionMethod = (transaction?: TransactionModel_interface): boolean => {
    const wallet = getWallet();

    if(!transaction || !wallet) return false;

    if(wallet.connectMethod === 'DeepLink' && !isMobile()) {
      setStatus('in_transfer');
      navigateToPaymentProcess();
      return true;
    }

    if(wallet.connectMethod === 'DeepLink' && isMobile()){
      const url = wallet.deeplinkFunction(transaction);
      if(!url) return false;

      goToDeeplink(url);
      setStatus('in_transfer');
      navigateToPaymentProcess();
      return true;
    }

    if(wallet.connectMethod === 'WalletConnect'){
      if(isConnected){
        setStatus('in_transfer');
        transfer();
        return true;
      }
      if(isDisconnected){
        connectWallet();
        return true;
      }
    }

    if(wallet.connectMethod === 'WithoutConnecting'){
      setStatus('in_transfer');
      navigateToPaymentProcess();
      return true;
    }

    return false;
  };

  const sessionsAnalytics = (payment?: string | null) => {
    const session_id = getSessionId();

    api.patch(`/sessions/${session_id}`, {
      new_payment_option: {
        wallet: getWallet()?.name,
        chain_id: getChain()?.id,
        ticker: getToken(),
      },
      payment_request_id: payment,
    });
  };

  const resolvePaymentCard = () => {
    const paymentRequest = getPaymentRequest();
    if(!isPaymentCardUSD || !paymentRequest || !paymentRequest.card_payment) return;

    goToDeeplink(paymentRequest.card_payment.session_url);
    return;
  };

  const createParamsRequest = () => {
    const currency = getCurrency();
    const reference = getReference();
    const amount = getAmount() ?? 0;

    const params = {
      amount, 
      currency,
      coins: isPaymentCardUSD ? [] : [coinsPayment], 
      reference,
      card_payment: isPaymentCardUSD,
      merchant_id: merchant.id
    } as createPayment_interface;

    return params;
  };
  
  const controlPayment = async () => {
    const paymentRequest = getPaymentRequest();
    
    //Si tengo payment request, busco la transaction para este token-chain
    try{
      if(paymentRequest && paymentRequest.id){
        sessionsAnalytics(paymentRequest.id);

        let transaction = getTransactionForTokenInChain();
  
        if(!transaction && !isPaymentCardUSD){
          const tx = await addTransactionForTokenChain();
          if(!tx) return;
          transaction = getTransactionForTokenInChain();
        }

        resolvePaymentCard();
        resolveConnectionMethod(transaction);
        return;
      }
      
      if(!paymentRequest?.id){

        const params = createParamsRequest();

        const data = await createPaymentRequest(params);
        
        if(data.status === 201){
          const paymentRequest: PaymentRequest_interface = data.data;
          sessionsAnalytics(paymentRequest.id);
          setPaymentRequest(paymentRequest);

          const transaction = getTransactionForTokenInChain();
          resolvePaymentCard();
          resolveConnectionMethod(transaction);
        }
        return;
      }
    }catch (e){
      console.warn('Catch control payment', e);
    }
  };

  return ({
    controlPayment,
    resolveConnectionMethod
  });
};

export default useControlPayment;