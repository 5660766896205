import { Box, Text } from '@radix-ui/themes';

import { Campaign_interface } from '@/interfaces/merchant.interfaces';

import { OptionSelect } from './select.interface';

const OptionBadge = ({badges, option, title = '% cashback*'}: {badges: Campaign_interface[] | undefined, option: OptionSelect, title?: string}) => {
  const optionBadge = badges?.find(o => o.name == option.value);

  if(optionBadge && optionBadge.discount > 0){
    return (
      <Box style={{display: 'flex',justifySelf: 'flex-end', borderRadius: '15px', padding: '0 12px', backgroundColor: '#9999f2', color: '#FFF'}}>
        <Text size={'2'} weight={'medium'} style={{color: '#FFF'}}>{optionBadge.discount}{title}</Text>
      </Box>
    );
  }
};

export default OptionBadge;