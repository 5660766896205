import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { createUrlProcess, createUrlResponse, createUrlSuccess } from '@/helpers/navigate';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';

import useCheck from './useCheck';

interface UseParams_interface {
  navigate: any;
  getParam: (param: string) => any; //TODO: return type
  goToDeeplink: (url: string) => void;
  navigateToPaymentProcess: () => void;
  navigateToPaymentSuccess: () => void;
  backToPayment: () => void,
  goToPaymentId: (id: string) => void,
  navigateError: () => void,
  navigateToStatic: () => void,
  navigateToResponse: () => void,
  navigateAmount: () => void
}

const useCustomNavigation = (): UseParams_interface => {
  const [searchParams, setResultParam] = useSearchParams();
  const { getPaymentRequest } = usePaymentRequestProvider();
  const navigate = useNavigate();
  const params = useParams();
  const { isStatic } = useCheck();

  const getParam = (param: string) => { //TODO: return type
    return searchParams.get(param);
  };

  const goToDeeplink = (url: string) => {
    if(!url) return;
    // if(isDevelop()){
    //   window.open(url, '_blank');
    //   return;
    // }
    window.location.href = url;
    return;
  };

  const navigateToPaymentProcess = () => {
    const paymentRequest = getPaymentRequest();
    if(!paymentRequest?.id || !params.slug) return;

    navigate(createUrlProcess({slug: params.slug, id: paymentRequest.id}), {replace: true});
  };

  const navigateToPaymentSuccess = () => {
    const paymentRequest = getPaymentRequest();
    if(!paymentRequest?.id || !params.slug) return;

    navigate(createUrlSuccess({slug: params.slug, id: paymentRequest.id}));
  };

  const backToPayment = () => {
    const paymentRequest = getPaymentRequest();
    
    if(!paymentRequest || !params.slug) return;

    if(paymentRequest.reference?._product_id){
      navigate(`/${params.slug}/products/${paymentRequest.reference._product_id}`, {replace: true});
      return;
    }

    navigate(`/${params.slug}/payment/${paymentRequest.id}`, {replace: true});
  };

  const goToPaymentId = (id: string) => {
    navigate(`/${params.slug}/payment/${id}${isStatic() ? '/static' : ''}`);
  };

  const navigateError = () => {
    navigate('error', {replace: true});
  };

  const navigateToStatic = () => {
    navigate(`/${params.slug}/static`, {replace: true});
  };

  const navigateToResponse = () => {
    const paymentRequest = getPaymentRequest();

    if(!paymentRequest || !params.slug || !paymentRequest.id) return;
    navigate(createUrlResponse({id: paymentRequest.id, slug: params.slug}));
  };

  const navigateAmount = () => {
    navigate('../');
  };

  return ({
    navigate,
    getParam,
    goToDeeplink,
    navigateToPaymentProcess,
    backToPayment,
    navigateToPaymentSuccess,
    goToPaymentId,
    navigateError,
    navigateToStatic,
    navigateToResponse,
    navigateAmount
  });
};

export default useCustomNavigation;