import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Callout, Flex } from '@radix-ui/themes';
import { useTranslation } from 'react-i18next';

const InfoPaymentCopy = () => {
  const { t } = useTranslation();

  return(
    <Callout.Root color='purple' variant="surface">
      <Callout.Icon>
        <InfoCircledIcon />
      </Callout.Icon>
      <Flex align={'center'}>
        <Callout.Text size={'2'} weight={'medium'}>
          {t('Enviá el monto exacto a la dirección indicada aquí abajo.')}
        </Callout.Text>
      </Flex>
    </Callout.Root>
  );
};

export default InfoPaymentCopy;