import './index.css';
import '@radix-ui/themes/styles.css';
import './i18n.js';

import Amount from '@pages/Amount/Amount';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import { loaderProducts, rootLoader } from './api/loaders';
import App from './App';
import Home from './pages/Home';
import Mint from './pages/Mint/Mint';
import Payment from './pages/Payment/Payment.index';
import ProcessPayment from './pages/Process/ProcessPayment';
import Products from './pages/Products/Products.index';
import Error from './pages/Response/Error';
import Response from './pages/Response/Response';
import Success from './pages/Response/Success';
import Root from './pages/Root';
import Static from './pages/StaticPayment/Static';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <Error/>,
    children: [
      {
        element: <Home />,
        index: true,
      },
      {
        path: 'mint',
        element: <Mint />,
      },
      {
        path: ':slug',
        element: <App />,
        id: 'root',
        loader: rootLoader,
        children: [
          {
            path: '',
            element: <Amount />,
            index: true,
          },
          {
            path: 'amount/:amount',
            element: <Payment />,
          },
          {
            path: 'payment/:payment_id/static?',
            element: <Payment />,
          },
          {
            path: 'products',
            element: <Products />,
          },
          {
            path: 'products/:product_id',
            element: <Payment />,
          },
          {
            path: 'payment/:payment_id/process',
            element: <ProcessPayment />,
          },
          {
            path: 'payment/:payment_id/success',
            element: <Success />,
          },
          {
            path: 'payment/:payment_id/response',
            element: <Response />,
          },
          {
            path: 'static',
            element: <Static />,
          }
        ]
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<RouterProvider router={router}/>);
