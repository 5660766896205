import './style.css';

import { Text } from '@radix-ui/themes';
import React from 'react';

interface InputAmountInterface {
  value: string;
  setValue: (value: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  currency?: string;
}

const InputAmount = ({ value, setValue, placeholder, currency }: InputAmountInterface) => {
  const width = Math.max(2.6, value.length);

  return (
    <div className='containerInput'>
      <input
        autoFocus
        type="text"
        inputMode='decimal'
        placeholder={placeholder}
        value={value}
        onChange={setValue}
        className="input-amount border-none"
        style={{width: width + 'ch'}}
      />
      <Text size={'1'} color='gray'>{currency}</Text>
    </div>
  );
};

export default InputAmount;