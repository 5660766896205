import { v4 as uuidv4 } from 'uuid';

import { getItemSession, getItemStorage, setItemSession, setItemStorage } from './storage';

export const getDeviceId = () => {
  const id = getItemStorage('device_id');
  if(!id){
    return setDeviceId();
  }
  return id;
};

export const setDeviceId = () => {
  const id = uuidv4();
  setItemStorage('device_id', id);
  return id;
};

export const setSessionId = (id: string) => {
  setItemSession('session_id', id);
  return;
};

export const getSessionId = () => {
  return getItemSession('session_id');
};