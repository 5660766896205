import { isDevelop } from '@/helpers/global.state';
import { getSessionId } from '@/helpers/sessions';
import { TransactionModel_interface } from '@/interfaces/transaction.interface';
import { TokenSymbol_Type } from '@/types/token.types';

import { BASE, BCH, ETHEREUM, LACHAIN, OPTIMISM, POLYGON, ZKSYNC } from '../CHAINS';

const BASE_URL = `${isDevelop() ? 'develop.' : ''}pagar.simplefi.tech`;
const PATHNAME = window.location.pathname?.split('/')?.[1] ?? '';

const ripio_formatForChain = {
  [POLYGON.id]: 'polygon',
  [OPTIMISM.id]: 'optimism',
  [ETHEREUM.id]: 'ethereum',
  [ZKSYNC.id]: 'zksync',
  [BASE.id]: 'base',
  [LACHAIN.id]: 'lachain',
  [BCH.id]: 'bitcoincash'
};

const lemon_formatForChain = {
  [ETHEREUM.id]: 'ERC20',
  [ZKSYNC.id]: 'ZKSYNC_ERA',
  [OPTIMISM.id]: 'OPTIMISM',
  [POLYGON.id]: 'POLYGON'
};

export const ripio_deeplink = (transaction: TransactionModel_interface | null): string | null => {
  if(!transaction) return null;

  const response_url = `https://${BASE_URL}/${PATHNAME}/payment/${transaction.payment_request_id}`;
  const callback_url = `merchant=simpleFi&success_url=${response_url}/process&error_url=${response_url}/error`;

  if(transaction.coin.toUpperCase() === 'BTC'){
    return `ripio://send/crypto/${transaction.additional_info?.lnurl}/lightning/BTC/${transaction.price_details.final_amount}?${callback_url}`;
  }

  const url = `ripio://send/crypto/${transaction.address}/${ripio_formatForChain[transaction.chain_id]}/${transaction.coin?.toUpperCase()}/${transaction.price_details.final_amount}?${callback_url}`;
  return url;
};

export const coinbase_deeplink = (transaction: TransactionModel_interface | null): string | null => {
  if(!transaction) return null;

  if (transaction?.coin.toUpperCase() === ('USDC' as TokenSymbol_Type) && transaction.chain_id === BASE.id) {
    const amountAsErc20 = transaction?.price_details?.final_amount * 10 ** 6;
    const url = `https://go.cb-w.com/send_token?bc=ETH&ci=8453&ca=0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913&am=${amountAsErc20}&addr=${transaction.address}`;
    return url;
  }

  const session = getSessionId();

  const dappUrl = encodeURIComponent(
    `${BASE_URL}/${PATHNAME}/payment/${transaction.payment_request_id}/?ticker=${transaction?.coin.toUpperCase()}&chain_id=${transaction.chain_id}&wallet=walletConnect${session ? `&session=${session}`: ''}`
  );
  const url = `https://go.cb-w.com/dapp?cb_url=${dappUrl}`;

  return url;
};

export const lemon_deeplink = (transaction: TransactionModel_interface | null): string | null => {
  if(!transaction) return null;

  const url = `https://www.lemon.me/app/cash-out-crypto?network=${lemon_formatForChain[transaction.chain_id]}&address=${transaction.address}&currency=${transaction.coin?.toUpperCase()}&amount=${transaction.price_details.final_amount}&partner=simplefi`;

  return url;
};

export const metamask_deeplink = (transaction: TransactionModel_interface | null): string | null => {
  if(!transaction) return null;

  const session = getSessionId();

  const url = `https://metamask.app.link/dapp/${BASE_URL}/${PATHNAME}/payment/${transaction.payment_request_id}/?ticker=${transaction?.coin.toUpperCase()}&chain_id=${transaction.chain_id}&wallet=walletConnect${session ? `&session=${session}`: ''}`;
  return url; 
};

export const bitcoin_deeplink = (transaction: TransactionModel_interface | null) => {
  if(!transaction) return null;

  const url = `lightning:${transaction.address}`;

  return url;
};