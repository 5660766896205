import { Flex } from '@radix-ui/themes';
import Lottie from 'lottie-react';

import LoaderAnimation from '@/assets/Icons/FlatIcons/LoaderAnimation.json';

const Loader = () => {
  return (
    <Flex style={{height: '100dvh', width: '100dvw'}} align={'center'} justify={'center'}>
      <Lottie animationData={LoaderAnimation} loop={true} />
    </Flex>
  );
};

export default Loader;