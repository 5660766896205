import { Button, ButtonProps } from '@radix-ui/themes';
import { ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { USD } from '@/assets/Constants/TOKENS';
import useControlPayment from '@/hooks/Payment/useControlPayment';
import useWallet from '@/hooks/Wallet/useWallet';
import { useCartContext } from '@/providers/Cart.provider';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';
import { useUserStatusProvider } from '@/providers/UserStatus.provider';

const ButtonPayment = (): ReactNode => {
  const params = useParams();
  const { getToken, getChain, getWallet } = usePaymentMethodProvider();
  const { getPaymentRequest, getReference } = usePaymentRequestProvider();
  const { getProduct } = useCartContext();
  const { isConnecting, isConnected } = useWallet();
  const { isInTransfer } = useUserStatusProvider();
  const isClicked = useRef(false);
  const { t } = useTranslation();
  
  const { controlPayment } = useControlPayment();
  const paymentRequest = getPaymentRequest();
  const wallet = getWallet();
  const token = getToken();
  const chain = getChain();
  const product = getProduct();
  const reference = getReference();
  
  const isWalletConnect = wallet?.connectMethod === 'WalletConnect';

  const hasLoadingPayment = !!params.payment_id && !paymentRequest;
  const invalidProduct = params.product_id ? product?.require_email && (!reference?._email || reference?._email?.length < 1) : false;

  const isDisabled = !token || !chain || (!wallet && token !== USD.ticker) || isConnecting || isInTransfer || hasLoadingPayment || isClicked.current || invalidProduct;
  const isLoading = isConnecting || isInTransfer || hasLoadingPayment || isClicked.current; 
  
  const handlePress = async () => {
    isClicked.current = true;
    await controlPayment();
    isClicked.current = false;
  };

  const resolveTitle = (): string => {
    if(token?.toUpperCase() === 'BTC') return t('Ir a pagar');
    
    if(isWalletConnect){
      return isConnected ? t('Pagar') : t('Conectar Wallet');
    }

    return t('Ir a pagar');
  };

  // console.log('isClicked', isClicked.current);
  // console.log('hasLoadingPayment',hasLoadingPayment);
  // console.log('isInTransfer',isInTransfer);
  // console.log('isConnecting',isConnecting);
  
  return(
    <ButtonDefault 
      onClick={handlePress} 
      disabled={isDisabled} 
      loading={isLoading} 
      title={resolveTitle()}
    />
  );
};

const ButtonDefault = ({onClick, disabled, loading, title, ...rest}: ButtonProps) => {

  return (
    <Button size={'3'} onClick={onClick} disabled={disabled} loading={loading} {...rest} >
      {title}
    </Button>
  );
};

export default ButtonPayment;