import { Button, Flex, Text } from '@radix-ui/themes';
import { useTranslation } from 'react-i18next';
import { useActiveAccount, useActiveWallet, useDisconnect, useWalletImage } from 'thirdweb/react';

import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';

const ConnectedWallet = () => {
  const { getWallet } = usePaymentMethodProvider();
  const { t } = useTranslation();

  const walletMethod = getWallet();
  const activeWallet = useActiveWallet();
  const activeAccount = useActiveAccount();
  const { disconnect } = useDisconnect();
  const walletImage = useWalletImage(activeWallet?.id);

  const handleClickDisconnect = () => {
    if(!activeWallet) return;
    disconnect(activeWallet);
  };

  if(activeWallet && activeAccount && walletMethod?.id === 'walletConnect'){
    return (
      <Flex direction={'column'} align={'end'} gap={'2'}>
        <Flex justify={'between'} align={'center'} style={{width: '100%', backgroundColor: 'rgba(91, 91, 214, .72)', padding: '6px 9px', borderRadius: '6px'}}>
          <Flex gap={'2'} align={'center'}>
            <img src={walletImage?.data} style={{borderRadius: '12px'}} width={'24px'} height={'24px'}/>
            <Text size={'2'} weight={'medium'} style={{color: '#FFF'}}>{t('Conectado a')}</Text>
          </Flex>
          <Text size={'2'} weight={'medium'} style={{color: '#FFF'}}>...{activeAccount.address.slice(-7)}</Text>
        </Flex>
        <Button size={'1'} variant='ghost' onClick={handleClickDisconnect}>{t('Desconectar')}</Button>
      </Flex>
    );
  }
};

export default ConnectedWallet;