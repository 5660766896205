import { Flex, Text, Theme } from '@radix-ui/themes';
import Lottie from 'lottie-react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { isRouteErrorResponse, useLocation, useRouteError } from 'react-router-dom';

import ErrorAnimation from '@/assets/Icons/FlatIcons/ErrorAnimation.json';
import ScreenWrapper from '@/components/ScreenWrapper/ScreenWrapper';
import SupportCall from '@/components/SupportCall/SupportCall';

const Error = () => {
  const error = useRouteError() as any;
  const location = useLocation();
  const { t } = useTranslation();

  const isErrorAmount = location.pathname.includes('/amount/');

  if(isErrorAmount){
    return(
      <ErrorWrapper>
        <Text align={'center'} size={'7'} weight={'medium'}>Error</Text>
        <Text align={'center'} size={'4'} weight={'medium'}>{t('El monto ingresado no es válido.')}</Text>
        <SupportCall/>
      </ErrorWrapper>
    );
  }

  if(isRouteErrorResponse(error) && error?.data?.msg) {
    return (
      <ErrorWrapper>
        <Text align={'center'} size={'6'} weight={'medium'}>{error?.data?.msg}</Text>
        <SupportCall/>
      </ErrorWrapper>
    );
  }

  return(
    <ErrorWrapper>
      <Text align={'center'} size={'7'} weight={'medium'}>Error 404</Text>
      <Text align={'center'} size={'4'} weight={'medium'}>Not found</Text>
      <SupportCall/>
    </ErrorWrapper>
  );
};

interface ErrorWrapper_interface{
  children: ReactNode,
  size?: 'small' | 'regular'
}

const ErrorWrapper = ({children, size = 'regular'}: ErrorWrapper_interface) => {

  const SIZE = {
    small: '180px',
    regular: '210px'
  };

  return(
    <Theme accentColor='iris'>
      <ScreenWrapper>
        <Flex direction={'column'} justify={'center'} align={'center'} height={'100%'} gap={'2'}>
          <Lottie animationData={ErrorAnimation} style={{width: SIZE[size]}} loop={false}/>
          {children}
        </Flex>
      </ScreenWrapper>
    </Theme>
  );
};

export default Error;