import { Button, Flex, Text } from '@radix-ui/themes';
import { useQuery } from '@tanstack/react-query';
import Lottie from 'lottie-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteLoaderData } from 'react-router-dom';

import api from '@/api';
import ErrorAnimation from '@/assets/Icons/FlatIcons/ErrorAnimation.json';
import ScreenWrapper from '@/components/ScreenWrapper/ScreenWrapper';
import { isPaymentPending } from '@/helpers/payment.multiuse';
import useCustomNavigation from '@/hooks/useCustomNavigation';
import { Merchant_interface } from '@/interfaces/merchant.interfaces';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';

import Loader from '../Process/Loader';

const Static = () => {
  const { setPaymentRequest } = usePaymentRequestProvider();
  const { goToPaymentId } = useCustomNavigation();
  const merchant: Merchant_interface = useRouteLoaderData('root') as Merchant_interface;
  const { t } = useTranslation();

  const { data, isSuccess, refetch, isLoading, isFetching } = useQuery({
    queryKey: ['/payment_requests/last', merchant.id],
    queryFn: () => api.get(`/payment_requests/last/${merchant.id}`),
  });

  const handleClickRefetch = () => {
    refetch();
  };

  useEffect(() => {
    if(isSuccess){
      const payment = data.data;
      const isPending = isPaymentPending(payment);
      if(payment && isPending){
        setPaymentRequest(payment);
        goToPaymentId(payment.id);
      }
    }
  }, [data, isSuccess]);

  if(isLoading){
    return(
      <Loader/>
    );
  }

  return (
    <ScreenWrapper>
      <Flex height={'100%'} width={'100%'} direction={'column'} align={'center'} justify={'center'} gap={'4'}>
        <Lottie animationData={ErrorAnimation} style={{width: '160px'}} loop={false}/>
        <Text size={'4'} weight={'medium'}>{t('static.order_not_found.title')}</Text>
        <Text size={'3'} weight={'medium'}>{t('static.order_not_found.subtitle')}</Text>
        <Button variant="outline" disabled={isFetching} onClick={handleClickRefetch}>{t('static.order_not_found.button')}</Button>
      </Flex>
    </ScreenWrapper>
  );
};

export default Static;