import { Button, Flex, Text } from '@radix-ui/themes';
import Lottie from 'lottie-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaXTwitter } from 'react-icons/fa6';
import { useParams, useRouteLoaderData } from 'react-router-dom';

import SuccessAnimation from '@/assets/Icons/FlatIcons/SuccessAnimation.json';
import ScreenWrapper from '@/components/ScreenWrapper/ScreenWrapper';
import { useGetPaymentRequest } from '@/hooks/Payment/useGetPaymentRequest';
import { Merchant_interface } from '@/interfaces/merchant.interfaces';
import { useCartContext } from '@/providers/Cart.provider';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';

const Success = () => {
  const { getPaymentRequest, getStatusPayment, getCurrency, getTransferData } = usePaymentRequestProvider();
  const { getToken, getChain, getWallet } = usePaymentMethodProvider();
  const { fetchPaymentRequest } = useGetPaymentRequest();
  const merchant: Merchant_interface = useRouteLoaderData('root') as Merchant_interface;
  const { t } = useTranslation();

  const params = useParams();
  fetchPaymentRequest(params.payment_id);

  const paymentRequest = getPaymentRequest();
  const status = getStatusPayment(); 
  const currency = getCurrency();

  const handleClickShareX = () => {
    const walletId = getWallet()?.id;
    const campaign = merchant.campaigns?.find(c => c.name === walletId);
    const discount = (campaign && campaign.discount > 0) ? `con ${campaign.discount}% de descuento en ${getWallet()?.name}` : null;
    const message = `Acabo de pagar en ${merchant.name} con mis ${getToken()} en ${getChain()?.name} ${discount ? discount : ''} de forma fácil y rápida. Recomendado! @SimpleFi_tech #Blockchain #cryptopayments`;
    // window.location.href = url
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`);
  };

  if(paymentRequest && status === 'approved'){
    return (
      <ScreenWrapper>
        <Flex height={'100%'} direction={'column'} align={'center'} style={{justifyContent: 'space-between'}}>
          <Flex direction={'column'} align={'center'}>
            <Text align={'center'} size={'2'} weight={'medium'} color='gray'>Orden</Text>
            <Text align={'center'} size={'6'} weight={'medium'}>{paymentRequest.order_id}</Text>
          </Flex>

          <Lottie animationData={SuccessAnimation} loop={false} style={{height: '210px'}}/>
          <Flex direction={'column'} align={'center'} justify={'center'} gap={'6'}>
            <CartItems/>
            <Flex direction={'column'} align={'center'} justify={'center'} gap={'2'}>
              <Text align={'center'} size={'7'} weight={'medium'}>{t('Pagaste')} {paymentRequest.amount?.toLocaleString('es')} <Text size={'6'}>{currency}</Text></Text>
              <Text align={'center'} size={'6'} weight={'medium'}>{t('a')} {merchant.name}</Text>

            </Flex>
            <Text align={'center'} size={'3'} weight={'medium'} color='violet'>{t('El pago fue realizado con éxito')}</Text>
          </Flex>
          <Button onClick={handleClickShareX} variant='outline'> 
            {t('Compartí tu experiencia en')} <FaXTwitter/>
          </Button>
        </Flex>
      </ScreenWrapper>
    );
  }
};

const CartItems = () => {
  const { getCart } = useCartContext();
  const cart = getCart();

  if(cart && cart.length > 0){
    return(
      <Flex direction={'column'} gap={'1'}>
        {
          cart.map(item => (
            <Flex key={`cart-item${item.id}`} align={'center'} gap={'1'}>
              <div style={{width: '6px', height: '6px', borderRadius: '50px',backgroundColor: '#000'}}></div>
              <Text size={'4'} weight={'medium'}>{item.name} x {item.quantity}</Text>
            </Flex>
          ))
        }
      </Flex>
    );
  }
};

export default Success;