import { OptionSelect } from '@/components/Select/select.interface';
import { Chain } from '@/interfaces/chain.interfaces';
import { CurrenciesApi_interface } from '@/interfaces/token.interface';
import { Wallet } from '@/interfaces/wallet.interface';
import { TokenSymbol_Type } from '@/types/token.types';

export const getCurrenciesForTokensInChain = (currencies: CurrenciesApi_interface[], tokens: TokenSymbol_Type[], chain: Chain) => {
  return currencies.filter(curr => (curr.chain_id === chain.id && tokens?.includes(curr.ticker.toUpperCase())));
};

export const getCurrenciesForChain = (currencies: CurrenciesApi_interface[], chain: Chain): CurrenciesApi_interface[] => {
  return currencies.filter(curr => curr.chain_id === chain.id);
};

export const getOptionsChainsForWallet = (wallet: Wallet): OptionSelect[] => {
  return wallet.supportsChains.map((chain: Chain) => ({label: chain.name, url_icon: chain.icon?.url, value: chain.id}) as OptionSelect);
};

export const getCurrenciesForTokenList = (currencies: CurrenciesApi_interface[], tokens: TokenSymbol_Type[]): CurrenciesApi_interface[] => {
  return currencies
    .filter(currency => tokens.includes(currency.ticker)) // Filtrar por tokens
    .reduce((acc, current) => {
      if (!acc.some(currency => currency.ticker === current.ticker)) {
        acc.push(current); // Agregar solo si no está el ticker ya
      }
      return acc;
    }, [] as typeof currencies);
};

export const getCurrenciesForToken = (currencies: CurrenciesApi_interface[], token: TokenSymbol_Type): CurrenciesApi_interface[] => {
  return currencies.filter((curr) => curr.ticker.toUpperCase() === token.toUpperCase());
};

export const getChainsInCurrencies = (currencies: CurrenciesApi_interface[], chains: Chain[]): Chain[] => {
  return chains.filter((chain) => currencies.find(curr => curr.chain_id === chain.id));
};

export const getTokensForWallet = (wallet: Wallet): TokenSymbol_Type[] => {
  if(!wallet || !wallet.supportsTokens) return [];

  return (Array.from(new Set(Object.values(wallet.supportsTokens).flat())));
};
