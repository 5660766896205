import { useRef } from 'react';
import { createThirdwebClient, CreateThirdwebClientOptions, ThirdwebClient } from 'thirdweb';

const useClient = () => {
  const client = useRef<ThirdwebClient>();

  if (!client.current) {
    const clientId = process.env.REACT_APP_CLIENT_ID;

    if (!clientId) {
      throw new Error('Missing clientId in environment variables');
    }

    const options: CreateThirdwebClientOptions = { clientId };

    client.current = createThirdwebClient(options);
  }

  return client.current;
};

export default useClient;
