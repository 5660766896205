import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import english from './i18n/english';
import spanish from './i18n/spanish';

const resources = {
  en: english,
  es: spanish
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['navigator', 'htmlTag', 'localStorage', 'cookie'], // Prioridades de detección
    },
    fallbackLng: 'es',
    whitelist: ['en', 'es'],
    debug: true,
    resources,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;