import { IconButton } from '@radix-ui/themes';

import logo_wsp from '../../assets/Icons/logo_wsp.png';

const SupportCall = () => {

  const handleClickSupport = () => {
    const number = '5491149697137';
    const url = `https://wa.me/${number}`;
    window.location.href = url;
    return;
  };

  return (
    <IconButton variant='soft' style={{position: 'absolute', bottom: 30, right: 30}} onClick={handleClickSupport}>
      <img src={logo_wsp} width={'42px'} height={'42px'}/>
    </IconButton>
  );
};

export default SupportCall;