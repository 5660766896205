import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '@/components/Select/Select';
import { OptionSelect } from '@/components/Select/select.interface';
import { CurrenciesApi_interface } from '@/interfaces/token.interface';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';

interface SelectToken_interface {
  currencies: CurrenciesApi_interface[],
  disabled?: boolean
}

const formatCurrenciesOption = (currencies: CurrenciesApi_interface[]): OptionSelect[]  => {
  return currencies.map(curr => ({
    label: curr.ticker,
    value: curr.ticker.toUpperCase(),
    url_icon: curr.currency_logo,
  } as OptionSelect));
};

const SelectToken = ({currencies, disabled}: SelectToken_interface): ReactNode => {
  const { getToken, setToken } = usePaymentMethodProvider();
  const { t } = useTranslation();
  const token = getToken();

  const defaultTokens: OptionSelect[] | [] = useMemo(() => {
    const currenciesFilter: CurrenciesApi_interface[] = currencies.reduce((acc: CurrenciesApi_interface[], current: CurrenciesApi_interface) => {
      if (!acc.some((curr: CurrenciesApi_interface) => curr.ticker === current.ticker)) {
        acc.push(current);
      }
      return acc;
    }, []);

    return formatCurrenciesOption(currenciesFilter);
  }
  , [currencies]);

  

  return (
    <Select.Title
      title={t('Moneda')}
      label={t('Seleccioná una moneda')}
      options={defaultTokens}
      setValue={(c) => setToken(c)}
      value={token ?? ''}
      placeholder={t('Seleccionar moneda')}
      disabled={disabled}
    />
  );
};

export default SelectToken;