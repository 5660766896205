import { Flex, Heading, Text } from '@radix-ui/themes';
import { ReactNode } from 'react';

interface TitleInterface {
  title: string;
  subtitle?: string;
  icon?: ReactNode
}

const Title = ({title, subtitle, icon}: TitleInterface) => {
  return (
    <Flex gap={'2'} align={'start'} justify={icon ? 'between' : 'start'}>
      <Flex direction={'column'} gap={'2'}>
        <Heading size="6" weight="medium" highContrast align={'left'}>{title}</Heading>
        <Text size="2" weight="regular">{subtitle}</Text>
      </Flex>
      {icon}
    </Flex>
  );
};

export default Title;