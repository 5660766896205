import { Flex, Text } from '@radix-ui/themes';

import { ProductCart_interface } from '@/interfaces/product.interfaces';

const CartItem = ({item}: {item: ProductCart_interface}) => {

  const price = item.price * (item.quantity ?? 1);
  const quantity = (item.quantity && item.quantity > 1) && `x ${item.quantity}`;

  return (
    <Flex width={'100%'} justify={'between'} style={{padding: '9px', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px', borderRadius: '3px'}}>
      <Text size={'3'} weight={'regular'}>{item.name} {quantity}</Text>
      <Flex direction={'column'} align={'end'} gap={'3'} justify={'center'}>
        <Text size={'4'} weight={'medium'}><Text size={'2'} weight={'light'}>$ </Text>{price}</Text>
      </Flex>
    </Flex>
  );
};

export default CartItem;