import { Button } from '@radix-ui/themes';
import { useState } from 'react';
import { IoCartOutline } from 'react-icons/io5';

import { useCartContext } from '@/providers/Cart.provider';

import CartDrawer from './CartDrawer';

const Cart = () => {
  const [open, setOpen] = useState(false);
  const { getCart } = useCartContext();
  const cart = getCart();
  
  if(!cart || cart.length === 0) return <div style={{width: '21px'}}></div>;

  return (
    <>
      <Button variant='ghost' onClick={() => setOpen(true)}>
        <IoCartOutline style={{fontSize: '21px'}}/>
      </Button>
      <CartDrawer open={open} setOpen={setOpen}/>
    </>
  );
};

export default Cart;