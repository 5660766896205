
import { SUPPORTED_CHAINS } from '@/assets/Constants/CHAINS';
import { bitcoin_deeplink } from '@/assets/Constants/WALLETS/DEEPLINKS.wallets';
import { WALLETS } from '@/assets/Constants/WALLETS/WALLETS';
import { Chain } from '@/interfaces/chain.interfaces';
import { Wallet } from '@/interfaces/wallet.interface';
import { ChainId_Type } from '@/types/chain.types';
import { TransferData } from '@/types/transaction.types';
import { WalletId_Type } from '@/types/wallet.types';

export const getChainFromId = (chain_id: ChainId_Type | null): Chain | null => {
  const chain = SUPPORTED_CHAINS.find((c: Chain) => c.id == chain_id);

  if(!chain) return null;
  
  return chain;
};

export const getWalletFromId = (wallet_id: WalletId_Type | null) => {
  if(!wallet_id) return null;
  const wallet = WALLETS.find((w: Wallet) => w.id === wallet_id);

  return wallet ?? null;
};


export const getUrlRequestFromTransaction = (transaction: TransferData | null): string | null => {
  // `ethereum:${transaction.address}@${transaction.chain_id}`
  if(!transaction || transaction.chain_id < 0) return null;

  if(transaction.chain_id === 0) {
    return bitcoin_deeplink(transaction);
  }
  
  const amount = BigInt(transaction.price_details.final_amount * Math.pow(10, transaction.decimals));

  if(transaction.chain_id === 1){
    return `ethereum:${transaction.address}?value=${amount}`;
  }

  const urlRequest = `ethereum:${transaction.contract_address}@${transaction.chain_id}/transfer?address=${transaction.address}&uint256=${amount}`;

  return urlRequest;
};