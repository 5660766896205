import { CopyIcon } from '@radix-ui/react-icons';
import { Flex, Grid, IconButton, Text } from '@radix-ui/themes';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { copyToClipboard } from '@/helpers/multiuse';

const RowPaymentInfo = ({title, subtitle, hidde, ticker}: {title: string, subtitle?: string, hidde?: boolean, ticker?:string}) => {
  const { t } = useTranslation();

  const handleClickCopy = () => {
    if(!subtitle) return;
    copyToClipboard(subtitle);
    toast.success(`${title} ${t('copiado al portapapeles')}`);
  };

  return(
    <Flex width={'100%'} gap={'9'} justify={'between'} align={'center'}>
      <Grid rows={'2'} columns={'1'} >
        <Text weight={'bold'} size={'2'} style={{whiteSpace: 'nowrap'}}>{title}</Text>
        <Text 
          weight={'medium'} size={'2'} 
          color='gray' 
          style={{whiteSpace: 'nowrap', overflow: hidde ? 'hidden' : 'visible', direction: hidde ? 'rtl' : 'ltr', textOverflow:'ellipsis'}}
        >{subtitle} {ticker}</Text>
      </Grid>
      <IconButton size={'2'} onClick={handleClickCopy}>
        <CopyIcon/>
      </IconButton>
    </Flex>
  );
};

export default RowPaymentInfo;