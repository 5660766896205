import { Button as ButtonRadix, ButtonProps as RadixButtonProps} from '@radix-ui/themes';
import React from 'react';

const Button: React.FC<RadixButtonProps>  = ({children, ...rest}) => {
  return (
    <ButtonRadix {...rest}>
      {children}
    </ButtonRadix>
  );
};

export default Button;