import { useActiveWalletConnectionStatus, useIsAutoConnecting } from 'thirdweb/react';
import { ConnectionStatus } from 'thirdweb/wallets';

import { Chain } from '@/interfaces/chain.interfaces';
import { CurrenciesApi_interface } from '@/interfaces/token.interface';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';
import { TokenSymbol_Type } from '@/types/token.types';

interface useWallet_interface {
  getSupportedChainsForActiveWallet: () => Chain[];
  getTokensInChainForActiveWallet: (currencies: CurrenciesApi_interface[] | null, chain: Chain | null) => CurrenciesApi_interface[] | [];
  isConnecting: boolean,
  isConnected: boolean,
  isDisconnected: boolean,
  getTokensForWallet: () => TokenSymbol_Type[]
}

const useWallet = (): useWallet_interface => {
  const { getWallet } = usePaymentMethodProvider();
  const activeWallet = getWallet();
  const status: ConnectionStatus = useActiveWalletConnectionStatus();
  const isAutoConnecting = useIsAutoConnecting();

  const isConnecting = status === 'connecting' || isAutoConnecting;
  const isConnected = status === 'connected';
  const isDisconnected = status === 'disconnected';

  function getSupportedChainsForActiveWallet(): Chain[] {
    if (!activeWallet) {
      return [];
    }
    return activeWallet.supportsChains;
  }

  const getTokensInChainForActiveWallet = (currencies: CurrenciesApi_interface[] | null, chain: Chain | null): CurrenciesApi_interface[] | [] => {
    if(!chain || !currencies || !activeWallet) return [];

    // Si la wallet soporta todos los tokens y todas las chains (customs)
    if (activeWallet.supportsCustomTokens && activeWallet.supportsChains.includes(chain)) {
      const tokens = currencies.filter(curr => (curr.enabled && curr.chain_id === chain.id));
      return tokens;
    }

    // Si tiene tokens predefinidos, devolverlos
    if (activeWallet.supportsTokens && activeWallet.supportsTokens[chain.id]) {
      const tickers = activeWallet.supportsTokens[chain.id];
      const tokens = currencies.filter(curr => (curr.enabled && curr.chain_id === chain.id && tickers.includes(curr.ticker.toUpperCase()) ));
      return tokens;
    }

    return [];
  };

  const getTokensForWallet = (): TokenSymbol_Type[] => {
    if(!activeWallet || !activeWallet.supportsTokens) return [];

    return (Array.from(new Set(Object.values(activeWallet.supportsTokens).flat())));
  };


  return ({
    getSupportedChainsForActiveWallet,
    getTokensInChainForActiveWallet,
    isConnected,
    isConnecting,
    isDisconnected,
    getTokensForWallet
  });
};

export default useWallet;