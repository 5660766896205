import { createContext, useContext, useState } from 'react';
import { useRouteLoaderData } from 'react-router-dom';

import { STRIPE } from '@/assets/Constants/CHAINS';
import { WALLETS } from '@/assets/Constants/WALLETS/WALLETS';
import { getItemStorage, removeItemStorage, setItemStorage } from '@/helpers/storage';
import { getChainFromId, getWalletFromId } from '@/helpers/web3.multiuse';
import { Chain } from '@/interfaces/chain.interfaces';
import { Merchant_interface } from '@/interfaces/merchant.interfaces';
import { CurrenciesApi_interface } from '@/interfaces/token.interface';
import { Wallet } from '@/interfaces/wallet.interface';
import { ChainId_Type } from '@/types/chain.types';
import { ChildrenType } from '@/types/generics.types';
import { ChainStorage_type, TokenStorage_type, WalletStorage_type } from '@/types/storage.types';
import { TokenSymbol_Type } from '@/types/token.types';
import { WalletId_Type } from '@/types/wallet.types';

interface PaymentMethodContext_interface {
  getWallet: () => Wallet | null;
  getChain: () => Chain | null;
  getToken: () => TokenSymbol_Type | null;
  setWallet: (wallet_id: WalletId_Type | null) => void;
  setChain: (chain_id: ChainId_Type | null) => void;
  setToken: (token_id: TokenSymbol_Type | null) => void;
  getCurrencyForToken: () => CurrenciesApi_interface | null;
  isPaymentCardUSD: boolean;
}

export const PaymentMethodContext = createContext<PaymentMethodContext_interface | null>(null);

export const PaymentMethodProvider = ({ children }: ChildrenType) => {
  const tokenStorage = getItemStorage(TokenStorage_type) ?? null;
  const chainStorage = getItemStorage(ChainStorage_type);
  const chainIdStorage = chainStorage ? Number(chainStorage) : null;
  const walletStorage = getItemStorage(WalletStorage_type) as WalletId_Type;

  const [token, setTokenState] = useState<TokenSymbol_Type | null>(tokenStorage);
  const [chain, setChainState] = useState<Chain | null>(getChainFromId(chainIdStorage));
  const [wallet, setWalletState] = useState<Wallet | null>(getWalletFromId(walletStorage) ?? null);
  const merchant: Merchant_interface = useRouteLoaderData('root') as Merchant_interface;
  const isPaymentCardUSD = token === 'USD' && chain?.id === STRIPE.id;

  const getWallet = (): Wallet | null => {
    return wallet;
  };

  const getChain = (): Chain | null => {
    return chain;
  };

  const getToken = (): TokenSymbol_Type | null => {
    return token;
  };

  const setWallet = (wallet_id: WalletId_Type | null): void => {
    const wallet = getWalletFromId(wallet_id);

    if(!wallet || !wallet_id) {
      setWalletState(null);
      removeItemStorage(WalletStorage_type);
      return;
    }

    setItemStorage(WalletStorage_type, wallet_id);
    setWalletState(wallet);
  };

  const setChain = (chain_id: ChainId_Type | null): void => {
    const chain = getChainFromId(chain_id);

    if(!chain) {
      setChainState(null);
      removeItemStorage(ChainStorage_type);
      return;
    }
    
    setItemStorage(ChainStorage_type, chain.id);
    setChainState(chain);
  };

  const setToken = (token_id: TokenSymbol_Type | null): void => {
    if(token_id){
      setItemStorage(TokenStorage_type, token_id);
      setTokenState(token_id);
      return;
    }

    removeItemStorage(TokenStorage_type);
    setTokenState(null);
    return;
  };

  const getCurrencyForToken = (): CurrenciesApi_interface | null => {
    const currencies = merchant?.currencies;
    const token = getToken();

    if(!token|| !currencies) return null;

    const currency = currencies.find(curr => curr.ticker.toUpperCase() === token.toUpperCase());

    if(!currency) return null;
    
    return currency;
  };

  return (
    <PaymentMethodContext.Provider
      value={{
        getWallet,
        getChain,
        getToken,
        setWallet,
        setChain,
        setToken,
        getCurrencyForToken,
        isPaymentCardUSD
      }}
    >
      {children}
    </PaymentMethodContext.Provider>
  );
};

export const usePaymentMethodProvider = (): PaymentMethodContext_interface => {
  const context = useContext(PaymentMethodContext);
  if (context === null) {
    throw new Error('usePaymentMethodProvider must be used within a PaymentProvider');
  }
  return context;
};
