import { Button, Flex, Text } from '@radix-ui/themes';
import Lottie from 'lottie-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ErrorAnimation from '@/assets/Icons/FlatIcons/ErrorAnimation.json';
import BottomSupport from '@/components/BottomSupport/BottomSupport';
import ScreenWrapper from '@/components/ScreenWrapper/ScreenWrapper';
import { isPaymentExpired, isPaymentPending } from '@/helpers/payment.multiuse';
import { useGetPaymentRequest } from '@/hooks/Payment/useGetPaymentRequest';
import useCheck from '@/hooks/useCheck';
import useCustomNavigation from '@/hooks/useCustomNavigation';
import { useCartContext } from '@/providers/Cart.provider';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';


const Response = () => {
  const { getPaymentRequest, setPaymentRequest } = usePaymentRequestProvider();
  const { goToPaymentId, navigateAmount, navigate } = useCustomNavigation();
  const { fetchPaymentRequest } = useGetPaymentRequest();
  const { isStatic } = useCheck();
  const { t } = useTranslation();
  const { getCart, cleanCart } = useCartContext();
  const params = useParams();
  const payment = getPaymentRequest();
  const isExpired = isPaymentExpired(payment);
  const isPending = isPaymentPending(payment);
  const cart = getCart();

  useEffect(() => {
    if(!payment && params.payment_id){
      fetchPaymentRequest(params.payment_id);
      return;
    }
    if(isPending && payment?.id){
      goToPaymentId(payment.id);
      return;
    }
  }, [isPending]);

  const handleClick = () => {
    setPaymentRequest(null);
    if(cart && cart.length > 0){
      cleanCart();
      navigate('../products');
      return;
    }
    navigateAmount();
  };

  const showNewPayment = !isStatic();

  if(isExpired){
    return (
      <ScreenWrapper>
        <Flex direction={'column'} style={{justifyContent: 'space-around'}} align={'center'} height={'100%'} gap={'2'}>
          <Flex direction={'column'} justify={'between'} align={'center'} gap={'3'}>
            <Lottie animationData={ErrorAnimation} style={{width: '210px'}} loop={false}/>
            <Text size={'6'} weight={'medium'}>{t('Pago expirado')}</Text>
          </Flex>
          {
            showNewPayment &&
            <Button variant='outline' onClick={handleClick}>{t('Nuevo pago')}</Button>
          }
        </Flex>
        <BottomSupport/>
      </ScreenWrapper>
    );
  }
};

export default Response;