import { ChevronLeftIcon } from '@radix-ui/react-icons';
import { Button, Flex, Skeleton, Text } from '@radix-ui/themes';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import useCheck from '@/hooks/useCheck';
import { Merchant_interface } from '@/interfaces/merchant.interfaces';
import { useCartContext } from '@/providers/Cart.provider';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';

import Cart from '../Cart/Cart.index';

interface PaymentInfo_interface {
  merchant: Merchant_interface;
}

interface HeaderInfo_interface {
  amount?: string | number; 
  title: string, 
  currency?: string, 
  loading?: boolean,
  subtitle?: string
}

const Header = ({merchant}: PaymentInfo_interface) => {
  const { getProduct } = useCartContext();
  
  const params = useParams();
  const { getPaymentRequest, getCurrency } = usePaymentRequestProvider();
  const paymentRequest = getPaymentRequest();
  const currency = getCurrency();
  const product = getProduct();

  const title = merchant.name;

  if(product){
    return(
      <WrapperInfo title={title} subtitle={product.name} currency={product.currency} amount={product.price}/>
    );
  }

  if(paymentRequest){
    return(
      <WrapperInfo title={title} currency={currency} amount={paymentRequest.amount}/>
    );
  }

  if(params.amount) {
    return (
      <WrapperInfo title={title} amount={params.amount} currency={currency}/>
    );
  }

  return(
    <WrapperInfo title={title} loading={true} currency={currency}/>
  );
};

const WrapperInfo = ({amount, title, currency, loading = false, subtitle}: HeaderInfo_interface) => {
  const { isStatic } = useCheck();
  const location = useLocation();
  const isProductRoute = location.pathname.includes('products');
  const showBackButton = !isStatic() && !isProductRoute;
  const navigate = useNavigate();

  const handlePressBack = () => {
    navigate(-1);
  };

  return(
    <Flex justify={'between'} align={'center'}>

      {
        showBackButton ? (
          <Flex align={'center'}>
            <Button variant='ghost' size={'1'} onClick={handlePressBack}><ChevronLeftIcon style={{width: '21px', height: '21px'}}/></Button>
          </Flex>
        ) : (
          <div style={{width: '21px'}}></div>
        )
      }

      <Flex direction={'column'} align={'center'}>
        <Text size={'3'} weight={'medium'}>{title}</Text>
        {
          subtitle && (
            <Text size={'2'} weight={'regular'}>{subtitle}</Text>
          )
        }
        <Skeleton loading={loading} width={'120px'}>
          <Text size={'8'} weight={'medium'}> <Text size={'4'} color='gray' weight={'regular'}>{currency?.toUpperCase()}</Text> {amount}</Text>
        </Skeleton>
      </Flex>

      <Cart/>
      
    </Flex>
  );
};

export default Header;