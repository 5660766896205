import { Button, Flex, Grid, Spinner, Text } from '@radix-ui/themes';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useRouteLoaderData } from 'react-router-dom';

import api from '@/api';
import Title from '@/components/Headers/Title';
import TotalCart from '@/components/Headers/TotalCart';
import ScreenWrapper from '@/components/ScreenWrapper/ScreenWrapper';
import { useCreatePayment } from '@/hooks/Payment/useCreatePayment';
import useCustomNavigation from '@/hooks/useCustomNavigation';
import { Merchant_interface } from '@/interfaces/merchant.interfaces';
import { Product_interface, ProductCart_interface } from '@/interfaces/product.interfaces';
import { createPayment_interface, PaymentRequest_interface } from '@/interfaces/transaction.interface';
import { useCartContext } from '@/providers/Cart.provider';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';

import Loader from '../Process/Loader';
import Product from './Product/Product.item';

const hasNewProductsInCart = (paymentRequest: PaymentRequest_interface | null, cart: any[] | []): boolean => {
  if(!paymentRequest || !paymentRequest.items || cart.length === 0) return true;

  if(paymentRequest.items?.length !== cart.length) return true;

  const sameProductsInCart = paymentRequest.items.every(item1 => {
    return cart.some(item2 => (item1.id === item2.id && item1.quantity === item2.quantity));
  });

  return !sameProductsInCart;
};

const Products = () => {
  const [loading, setLoading] = useState(false);
  const { mutateAsync: createPaymentRequest }  = useCreatePayment();
  const { setProducts, getCurrency, setPaymentRequest, getReference, getPaymentRequest } = usePaymentRequestProvider();
  const { addProductToCart, removeProductCart, getProductCart, getCart } = useCartContext();
  const { goToPaymentId } = useCustomNavigation();
  
  const merchant: Merchant_interface = useRouteLoaderData('root') as Merchant_interface;

  const params = JSON.stringify({merchant_id: merchant.id});
  const { data, isSuccess, refetch, isLoading, isFetching } = useQuery({
    queryKey: ['products', merchant.id],
    queryFn: () => api.get(`/products?filter=${params}`),
  });

  const { t } = useTranslation();
  const currency = getCurrency();
  const cart = getCart();
  const paymentRequest = getPaymentRequest();

  const handleAddProduct = (product: Product_interface) => {
    addProductToCart(product);
  };

  const handleRemoveProduct = (product: Product_interface) => {
    removeProductCart(product.id);
  };

  const handleClickContinue = async () => {
    if(!cart) return;
    const reference = getReference();
    setLoading(true);
    setProducts(cart);

    const items = cart.map(item => ({id: item.id, quantity: item.quantity }));

    const newProductsInCart = hasNewProductsInCart(paymentRequest, items);

    if(!newProductsInCart && paymentRequest){
      if(!paymentRequest.id) return;
      goToPaymentId(paymentRequest.id);
      setLoading(false);
      return;
    }

    const params = { currency, items, reference, merchant_id: merchant.id } as createPayment_interface;

    const data = await createPaymentRequest(params);

    setLoading(false);

    if(data.status === 201){
      const paymentRequest: PaymentRequest_interface = data.data;
      if(!paymentRequest.id) return;
      
      setPaymentRequest(paymentRequest);
      goToPaymentId(paymentRequest.id);
    }
  };

  const isDisabled = cart?.length === 0 || loading;

  if(!data) return <Loader/>;

  const groupedProducts = data.data?.results?.reduce((acc: any, item: any) => {
    if (!acc[item.type]) {
      acc[item.type] = [];
    }
    acc[item.type].push(item);
    return acc;
  }, {});

  return (
    <ScreenWrapper>
      <Grid columns={'1'} rows={'1fr 6fr auto'} height={'100%'} justify={'between'}>
        <Flex justify={'between'}>
          <Title title={merchant.name} subtitle={t('Products')}/>
          <TotalCart/>
        </Flex>

        {/* scrollbarWidth: 'none', msOverflowStyle: 'none', */}
        <Flex gap={'6'} direction={'column'} overflow={'auto'} style={{ padding: '21px 0 30px 0', marginBottom: '9px'}}>
          {
            Object.keys(groupedProducts).map((type) => (
              <div key={`group-${type}`} style={{marginTop: '12px'}}>
                <Text size={'5'} weight={'medium'}>{type}</Text>
                <div style={{marginTop: '9px'}}>
                  {groupedProducts[type].map((item: any) => (
                    <Product
                      key={`Product ${item.id}`}
                      item={item}
                      addProduct={handleAddProduct}
                      removeProduct={handleRemoveProduct}
                      defaultCount={getProductCart(item.id)?.quantity}
                    />
                  ))}
                </div>
              </div>
            ))
          }
        </Flex>

        <Button size={'3'} style={{width: '100%'}} onClick={handleClickContinue} disabled={isDisabled} loading={loading}>{t('Continuar')}</Button>
      </Grid>
    </ScreenWrapper>
  );
};

export default Products;