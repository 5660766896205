import { Button, Flex } from '@radix-ui/themes';
import Lottie from 'lottie-react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import LoaderAnimation from '@/assets/Icons/FlatIcons/LoaderAnimation.json';
import BottomSupport from '@/components/BottomSupport/BottomSupport';
import ScreenWrapper from '@/components/ScreenWrapper/ScreenWrapper';
import { isMobile } from '@/helpers/global.state';
import useCustomNavigation from '@/hooks/useCustomNavigation';
import { useUserStatusProvider } from '@/providers/UserStatus.provider';

const PaymentProcessComponent = ({children, size = 'default'}: {children: ReactNode, size?: 'default' | 'small'}) => {
  const { t } = useTranslation();
  const { setStatus } = useUserStatusProvider();
  const { backToPayment } = useCustomNavigation();

  const handleClickCancel = () => {
    setStatus('pending');
    backToPayment();
  };

  return(
    <ScreenWrapper>
      <Flex height={size === 'default' ? '100%' : '92%'} width={'100%'} direction={'column'} align={'center'} style={{justifyContent: 'space-around'}}>
        {
          isMobile() && (
            <Lottie animationData={LoaderAnimation} loop={true} style={{width: size === 'default' ? '180px' : '120px'}}/>
          )
        }
        {children}
        <Button onClick={handleClickCancel}>{t('Cancelar')}</Button>
      </Flex>
      <div>
        <BottomSupport/>
      </div>
    </ScreenWrapper>
  );
};

export default PaymentProcessComponent;