import { Button, Flex, Text } from '@radix-ui/themes';

import ScreenWrapper from '@/components/ScreenWrapper/ScreenWrapper';

const Home = () => {
  
  return (
    <ScreenWrapper>
      <Flex height={'100%'} justify={'center'} align={'center'}>
        <Text size={'4'} weight={'medium'}>SimpleFi</Text>
      </Flex>
    </ScreenWrapper>
  );
};

export default Home;