import { Button, Flex, TextField } from '@radix-ui/themes';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';

import api from '@/api';
import { usePaymentMethodProvider } from '@/providers/PaymentMethod.provider';
import { usePaymentRequestProvider } from '@/providers/PaymentRequest.provider';

const SendTransactionHash = () => {
  const [hash, setHash] = useState('');
  const { getChain, getToken } = usePaymentMethodProvider();
  const { getPaymentRequest, setPaymentRequest } = usePaymentRequestProvider();

  const ticker = getToken();
  const chain_id = getChain()?.id;
  const paymentRequest = getPaymentRequest();

  const mutation = useMutation({
    mutationFn:  ({hash, id}: {hash: string, id: string}) => api.put(`/payment_requests/${id}/link_hash`, {hash, chain_id, ticker})
  });

  const handleSendHash = async () => {
    if(!ticker || !chain_id || !paymentRequest || !hash || hash.length < 1) return;

    const response = await mutation.mutateAsync({hash, id: paymentRequest.id ?? ''});

    if(response.status === 404){
      toast.error('No encontramos esa transacción, por favor verificá el hash.');
      return;
    }
    if(response.status === 200){
      setPaymentRequest(response.data.data);
      return;
    }
  };

  const handleChangeHash = (e: any) => {
    const value = e.target.value;
    setHash(value);
  };

  return (
    <Flex gap={'4'} width={'100%'} justify={'between'} align={'center'} onClick={(e) => e.stopPropagation()}>
      <TextField.Root size={'2'} style={{width: '100%'}} placeholder="Hash" onChange={handleChangeHash}/>
      <Button size={'2'} onClick={handleSendHash} disabled={mutation.isPending}>Enviar</Button>
    </Flex>
  );
};

export default SendTransactionHash;