import { createContext, useContext, useEffect, useState } from 'react';

import { Product_interface, ProductCart_interface } from '@/interfaces/product.interfaces';
import { ChildrenType } from '@/types/generics.types';

import { usePaymentRequestProvider } from './PaymentRequest.provider';

interface CartContext_interface {
  getCart: () => ProductCart_interface[] | [];
  addProductToCart: (product: ProductCart_interface) => void;
  getProductCart: (product_id: string) => ProductCart_interface | undefined;
  removeProductCart: (product_id: string) => void;
cleanCart: () => void,
  //Deprecated
  getProduct: () => Product_interface | null;
  setProduct: (product: Product_interface | null) => void;
}

export const CartContext = createContext<CartContext_interface | null>(null);

export const CartProvider = ({ children }: ChildrenType) => {
  const [cart, setCart] = useState<ProductCart_interface[] | []>([]);
  const [productData, setProductData] = useState<Product_interface | null>(null);

  const { getPaymentRequest } = usePaymentRequestProvider();

  const paymentRequest = getPaymentRequest();

  const getCart = () => {
    return cart;
  };

  useEffect(() => {
    if(paymentRequest?.items && cart.length === 0){
      setCart(paymentRequest.items);
    }
  }, [paymentRequest]);

  const addProductToCart = (product: ProductCart_interface): void => {
    const product_cart = cart.find((item) => item.id === product.id);

    if(product_cart && product_cart.max_items && product_cart.quantity && product_cart.quantity >= product_cart.max_items) return;

    setCart((prevCart) => {
      if (product_cart) {
        return prevCart.map((item) =>
          item.id === product.id
            ? { ...product, quantity: item.quantity ? item.quantity + 1 : 1}
            : item
        );
      } else {
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  };

  const getProductCart = (product_id: string): ProductCart_interface | undefined => {
    return cart.find(c => c.id === product_id);
  };

  const removeProductCart = (product_id: string): void => {
    const products = cart.map(item => {
      if(item.id === product_id){
        if(item.quantity === 1){
          return null;
        }
        if(item.quantity && item.quantity > 1){
          return ({...item, quantity: item.quantity - 1});
        }
      }
      return item;
    }).filter(item => item !== null);

    setCart(products);
  };

  const cleanCart = () => {
    setCart([]);
  };


  //Deprecated
  const getProduct = (): Product_interface | null => {
    return productData;
  };

  const setProduct = (product: Product_interface | null): void => {
    setProductData(product);
  };

  return (
    <CartContext.Provider
      value={{
        getCart,
        addProductToCart,
        getProductCart,
        removeProductCart,
        cleanCart,
        getProduct,
        setProduct
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = (): CartContext_interface => {
  const context = useContext(CartContext);
  if (context === null) {
    throw new Error('useCartContext must be used within a CartProvider');
  }
  return context;
};
