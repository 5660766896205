import {coinbase, lemon, metamask, ripio,wallet, walletConnect} from '@assets/Icons';

import i18n from '@/i18n';
import { Wallet } from '@/interfaces/wallet.interface';

import { BCH, ETHEREUM, LACHAIN, LIGHTING, OPTIMISM, POLYGON, SUPPORTED_CHAINS, ZKSYNC } from '../CHAINS';
import { bitcoin_deeplink, coinbase_deeplink, lemon_deeplink, metamask_deeplink, ripio_deeplink } from './DEEPLINKS.wallets';

export const WALLETS: Wallet[] = [
  {
    id: 'ripio',
    name: 'Ripio',
    icon: ripio,
    supportsChains: [OPTIMISM, POLYGON, LACHAIN, ETHEREUM, LIGHTING],
    supportsTokens: {
      [POLYGON.id]: ['USDT', 'USDC'],
      [OPTIMISM.id]: ['USDT', 'USDC'],
      [LACHAIN.id]: ['UXD'],
      [ETHEREUM.id]: ['ETH'],
      [LIGHTING.id]: ['BTC'],
    },
    connectMethod: 'DeepLink',
    deeplinkFunction: ripio_deeplink
  },
  {
    id: 'coinbase',
    name: 'Coinbase Wallet',
    icon: coinbase,
    supportsChains: SUPPORTED_CHAINS,
    supportsCustomChains: true,
    supportsCustomTokens: true,
    connectMethod: 'DeepLink',
    deeplinkFunction: coinbase_deeplink
  },
  {
    id: 'lemon',
    name: 'Lemon',
    icon: lemon,
    supportsChains: [POLYGON, ETHEREUM, OPTIMISM],
    supportsTokens: {
      [POLYGON.id]: ['USDT', 'USDC'],
      [ETHEREUM.id]: ['USDT', 'USDC', 'ETH'],
      [OPTIMISM.id]: ['USDT', 'USDC', 'ETH'],
    },
    connectMethod: 'DeepLink',
    deeplinkFunction: lemon_deeplink
  },
  {
    id: 'metamask_deeplink',
    name: 'Metamask',
    icon: metamask,
    supportsChains: SUPPORTED_CHAINS,
    supportsCustomTokens: true,
    supportsCustomChains: true,
    connectMethod: 'DeepLink',
    deeplinkFunction: metamask_deeplink
  },
  {
    id: 'walletConnect',
    name: 'Wallet Connect',
    icon: walletConnect,
    supportsChains: SUPPORTED_CHAINS,
    supportsCustomTokens: true,
    supportsCustomChains: true,
    connectMethod: 'WalletConnect',
    walletConnectId: 'walletConnect'
  },
  {
    id: 'invoice',
    name: i18n.t('Wallet default de tu dispositivo'),
    icon: wallet,
    supportsChains: [LIGHTING],
    supportsTokens: {
      [LIGHTING.id]: ['BTC']
    },
    connectMethod: 'DeepLink',
    deeplinkFunction: bitcoin_deeplink
  },
  {
    id: 'other',
    name: i18n.t('Transferí desde una wallet'),
    icon: wallet,
    supportsChains: SUPPORTED_CHAINS,
    supportsCustomTokens: true,
    supportsCustomChains: true,
    connectMethod: 'WithoutConnecting'
  },
];