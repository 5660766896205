import { Theme } from '@radix-ui/themes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { ThirdwebProvider, useAutoConnect } from 'thirdweb/react';

import useClient from '@/hooks/useClient';
import useControllerParams from '@/hooks/useControllerParams';
import { CartProvider } from '@/providers/Cart.provider';
import { PaymentMethodProvider } from '@/providers/PaymentMethod.provider';
import PaymentRequestProvider from '@/providers/PaymentRequest.provider';
import UserStatusProvider from '@/providers/UserStatus.provider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});


const Root = (): ReactNode => {
  return ( //appearance="dark"
    <Theme accentColor='iris'>
      <QueryClientProvider client={queryClient}>
        <ThirdwebProvider>
          <PaymentMethodProvider>
            <PaymentRequestProvider>
              <CartProvider>
                <UserStatusProvider>
                  <MainApp/>
                </UserStatusProvider>
              </CartProvider>
            </PaymentRequestProvider>
          </PaymentMethodProvider>
        </ThirdwebProvider>
      </QueryClientProvider>
    </Theme>
  );
};

const MainApp = () => {
  const client = useClient();
  
  useControllerParams();
  useAutoConnect({client});

  return(
    <Outlet/>
  );
};

export default Root;