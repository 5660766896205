import { createContext, useContext, useRef, useState } from 'react';
import { useNavigate, useParams, useRouteLoaderData, useSearchParams } from 'react-router-dom';

import { createUrlResponse } from '@/helpers/navigate';
import useAddTransactionPayment from '@/hooks/Payment/useAddTransactionPayment';
import useVerifyStatusPayment from '@/hooks/Payment/useVerifyStatusPayment';
import { Merchant_interface } from '@/interfaces/merchant.interfaces';
import { Product_interface } from '@/interfaces/product.interfaces';
import { PaymentRequest_interface, ReferenceProduct_interface, TransactionModel_interface } from '@/interfaces/transaction.interface';
import { ChildrenType } from '@/types/generics.types';
import { PaymentRequestStatus, TransferData } from '@/types/transaction.types';

import { usePaymentMethodProvider } from './PaymentMethod.provider';

interface PaymentRequestContext_interface {
  getAmount: () => number | null;
  setAmount: (value: number) => void,
  getReference: () => any,
  setReference: (reference: any) => void
  getPaymentRequest: () => PaymentRequest_interface | null;
  setPaymentRequest: (paymentRequest: PaymentRequest_interface | null ) => void;
  getStatusPayment: () => PaymentRequestStatus | null;
  getTransactionForTokenInChain: (paymentParam?: PaymentRequest_interface) => TransactionModel_interface | undefined;
  getTransferData: () => TransferData | null;
  addTransactionForTokenChain: () => any;
  getCurrency: () => string;
  setProducts: (product: Product_interface[]) => void;
  getProducts: () => Product_interface[] | undefined
}

export const PaymentRequestContext = createContext<PaymentRequestContext_interface | null>(null);

const PaymentRequestProvider = ({ children }: ChildrenType) => {
  const [searchParams] = useSearchParams();
  const [referenceData, setReferenceData] = useState({});
  const [amount, setAmountState] = useState<number | null>(null);
  const paymentRequest = useRef<PaymentRequest_interface | null>(null); //se usa ref por el async de useState al momento de addTransaction
  const [_, setUpdated] = useState(false); //esto para usar la re-render de cuando se actualiza la paymentRequest

  const { getToken, getChain, getWallet } = usePaymentMethodProvider();
  const merchant: Merchant_interface = useRouteLoaderData('root') as Merchant_interface;
  const { mutateAsync: mutateAddTransaction } = useAddTransactionPayment();
  const { verifyPaymentStatus } = useVerifyStatusPayment();
  const navigate = useNavigate();
  const params = useParams();

  const getAmount = (): number | null => {
    if(paymentRequest.current?.amount){
      return paymentRequest.current.amount;
    }
    return amount;
  };

  const setReference = (data: any) => {
    setReferenceData(prev => ({...prev, ...data}));
  };

  const getReference = () => {
    return ({...paymentRequest.current?.reference, ...referenceData});
  };

  const getCurrency = () => {
    const paramsCurrency = searchParams.get('currency');
    const currency = paymentRequest.current?.currency || paramsCurrency || 'ARS';
    return currency;
  };

  const setAmount = (value: number) => {
    setAmountState(value);
  };

  const getPaymentRequest = (): PaymentRequest_interface | null => {
    return paymentRequest.current;
  };

  const setPaymentRequest = (payment: PaymentRequest_interface | null) => {
    paymentRequest.current = payment;
    verifyPaymentStatus(payment);
    setUpdated(prev => !prev);
  };

  const getStatusPayment = (): PaymentRequestStatus | null => {
    if(!paymentRequest.current?.status) return null;
    return paymentRequest.current.status;
  };

  const getTransactionForTokenInChain = (): TransactionModel_interface | undefined => {
    if(!getToken() || !getChain() || !getWallet() || !paymentRequest.current) return undefined;

    const payment = paymentRequest.current;

    if(!payment.transactions) return;

    return payment?.transactions.find((tx) => (tx.chain_id === getChain()?.id && tx.coin.toUpperCase() === getToken()?.toUpperCase()));
  };

  const getTransferData = (): TransferData | null => {
    const transaction = getTransactionForTokenInChain();
    const [chain, token] = [getChain(), getToken()];

    if(!merchant || !transaction || !chain || !token) return null;


    const transferData = merchant.currencies.find(curr => (chain.id === curr.chain_id && token.toUpperCase() === curr.ticker.toUpperCase()));

    if(transferData){
      return ({...transferData, ...transaction});
    }

    return null;
  };

  const setProducts = (products: Product_interface[]) => {
    paymentRequest.current = {...paymentRequest.current, items: products};
  };

  const getProducts = (): Product_interface[] | undefined => {
    return paymentRequest.current?.items;
  };

  const addTransactionForTokenChain = async (): Promise<any>  => {
    const [token, chain] = [getToken(), getChain()];

    if(!paymentRequest.current || !token || !chain) return null;

    if(!paymentRequest.current.id) return;

    const responseMutate = await mutateAddTransaction({
      data: {
        ticker: token.toUpperCase(),
        chain_id: chain.id
      },
      id: paymentRequest.current.id
    });

    if(responseMutate.status === 400){
      paymentRequest.current = {...paymentRequest.current, status: 'expired'};
      if(!paymentRequest.current.id) return;
      navigate(createUrlResponse({id: paymentRequest.current.id, slug: params.slug ?? ''}));
      return null;
    }
    
    if(responseMutate.status === 200){
      const payment = responseMutate.data;
      setPaymentRequest(payment);
      return payment;
    }

    return null;
  };

  return (
    <PaymentRequestContext.Provider
      value={{
        getAmount,
        setAmount,
        setReference,
        getReference,
        getPaymentRequest,
        setPaymentRequest,
        getStatusPayment,
        setProducts,
        getProducts,
        getTransactionForTokenInChain,
        getTransferData,
        addTransactionForTokenChain,
        getCurrency
      }}
    >
      {children}
    </PaymentRequestContext.Provider>
  );
};


export const usePaymentRequestProvider = (): PaymentRequestContext_interface => {
  const context = useContext(PaymentRequestContext);
  if (context === null) {
    throw new Error('usePaymentRequestProvider must be used within a PaymentProvider');
  }
  return context;
};

export default PaymentRequestProvider;